@import "./Chapter.scss";

:root {
	--mapRatio: 2304 / 4096;
}

body {
	overflow-x: hidden;
}

body.Body {
	overflow: auto;
	/* this will hide the scrollbar in mozilla based browsers */
	overflow: -moz-scrollbars-none;
	/* this will hide the scrollbar in internet explorers */
	-ms-overflow-style: none;
}

body.Body::-webkit-scrollbar {
	width: 0 !important;
	display: none;
}

.Map.ZH-HANT {
	.TransMask .MaskOne .MaskTitle {
		margin-top: 1vw;

		p {
			font-size: 4.5vw !important;
			font-weight: 600 !important;
		}
	}

	.TransMask .MaskTwo h1 {
		font-size: 4vw !important;
		font-weight: 600 !important;
	}

	.TutorialOne {
		width: 9.5vw !important;
		left: 43% !important;
	}

	.TutorialTwo {
		width: 15.5vw !important;
		left: 6% !important;
	}

	.LeftFooter p {
		font-weight: 600 !important;
	}

	.ShareExp p {
		font-weight: 700;
	}
}

.Background {
	width: 100vw;
	height: 100vh;
	background-color: #c4cece;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	pointer-events: initial;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;

	img {
		width: 100vw;
		height: auto;
	}

	#BackgroundMap,
	.BackgroundContainer {
		position: absolute;
	}

	#BackgroundMap {
		background-image: url("../Assets/Images/Map/Background.png");
		background-repeat: no-repeat;
		background-size: contain;
	}

	.LeftFooter {
		position: absolute;
		left: 2vw;
		bottom: 2.2vw;

		p {
			font-family: adorn-coronet, sans-serif;
			font-size: 1.5vw;
			color: #192d42;
		}
	}

	.KeepSelected {
		width: 80%;
		height: 80%;
		position: absolute;
		left: 10%;
		top: 10%;
		border-radius: 45%;
	}
}

.Background.disable {
	pointer-events: none;
	animation-play-state: paused;
}

.Composer {
	position: absolute;
	height: 25%;
	width: 10vw;
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	z-index: 100;
}

.Composer.Dvorak {
	top: 22.5%;
	left: 2.5%;
	background-image: url("../Assets/Images/Map/Composer/Dvorak_color.png");
}

.Composer.Janacek {
	height: 28%;
	width: 13vw;
	bottom: 4%;
	right: 5%;
	background-image: url("../Assets/Images/Map/Composer/Janacek_color.png");
}

.Composer.Martinu {
	height: 29%;
	width: 13.5vw;
	top: 8.5%;
	left: 62.5%;
	background-image: url("../Assets/Images/Map/Composer/Martinu_color.png");
}

.Composer.Smetana {
	height: 25%;
	width: 12vw;
	bottom: 9%;
	left: 22%;
	background-image: url("../Assets/Images/Map/Composer/Smetana_color.png");
}

.City,
.Building,
.Character,
.Sun,
.Moon {
	position: absolute;
	background-size: contain;
	background-repeat: no-repeat;
	z-index: 20;
}

.City {
	height: 5%;
}

.City.Brno {
	width: 3.6vw;
	bottom: 35.5%;
	right: 42.9%;
	background-image: url("../Assets/Images/Map/City/Brno.png");
}

.City.Kladno {
	width: 4.5vw;
	top: 26%;
	left: 24%;
	background-image: url("../Assets/Images/Map/City/Kladno.png");
}

.City.Liberec {
	width: 5vw;
	top: 13.5%;
	left: 41.5%;
	background-image: url("../Assets/Images/Map/City/Liberec.png");
}

.City.Olomouc {
	width: 6vw;
	top: 44%;
	right: 15.5%;
	background-image: url("../Assets/Images/Map/City/Olomouc.png");
}

.City.Ostrava {
	width: 6vw;
	top: 46%;
	right: 4.7%;
	background-image: url("../Assets/Images/Map/City/Ostrava.png");
}

.City.Pardubice {
	width: 7vw;
	top: 36%;
	right: 26%;
	background-image: url("../Assets/Images/Map/City/Pardubice.png");
}

.City.Prague {
	width: 4.5vw;
	top: 37.5%;
	left: 36%;
	background-image: url("../Assets/Images/Map/City/Prague.png");
}

.City.Příbram {
	width: 5.5vw;
	bottom: 31%;
	left: 19.1%;
	background-image: url("../Assets/Images/Map/City/Příbram.png");
}

.City.Zlín {
	width: 4vw;
	bottom: 33%;
	right: 17%;
	background-image: url("../Assets/Images/Map/City/Zlín.png");
}

.Building {
	cursor: pointer;
	transition: all 0.5s;
}

.Building.JanacekTheatre {
	width: 12.5vw;
	height: 12.5%;
	bottom: 22%;
	right: 41%;
	background-image: url("../Assets/Images/Map/Building/Brno_Janáček_Theatre_c.png");
}

.Building.MahenTheatre {
	width: 25vw;
	height: 20vw;
	bottom: 4%;
	right: 15%;
	z-index: 15;
	background-image: url("../Assets/Images/Map/Building/Brno_Mahen_Theatre_day.png");
	animation: MahenTheatreTimeFlies infinite 8s;
}

.Building.RedutaTheatre {
	width: 16.5vw;
	height: 15%;
	bottom: 7%;
	right: 46.8%;
	background-image: url("../Assets/Images/Map/Building/Brno_Reduta_Theatre_c.png");
}

.Building.JanacekHouse {
	width: 14vw;
	height: 14.5%;
	bottom: 28.5%;
	right: 45%;
	background-image: url("../Assets/Images/Map/Building/Brno_The_Leoš_Janáček_House_c.png");
}

.Building.OrganistHouse {
	width: 14vw;
	height: 15%;
	left: 24%;
	top: 10%;
	background-image: url("../Assets/Images/Map/Building/Kladno_Organist_House_c.png");
}

.Building.SychrovCastle {
	width: 18vw;
	height: 15%;
	top: 11.5%;
	left: 45.5%;
	background-image: url("../Assets/Images/Map/Building/Liberec_Sychrov_Castle_c.png");
}

.Building.MoravianTheatre {
	width: 7vw;
	height: 12%;
	bottom: 38.5%;
	right: 15%;
	background-image: url("../Assets/Images/Map/Building/Olomouc_Moravian_Theatre_Olomouc_c.png");
}

.Building.MonumentOfBystrouška {
	width: 8vw;
	height: 14%;
	bottom: 43%;
	right: 7.5%;
	background-image: url("../Assets/Images/Map/Building/Ostrava_Monument_of_Bystrouška_c.png");
}

.Building.LitomyšlCastle {
	width: 11vw;
	height: 15%;
	bottom: 44.5%;
	right: 22.1%;
	background-image: url("../Assets/Images/Map/Building/Pardubice_Litomyšl_Castle_c.png");
}

.Building.StJamesChurch {
	width: 12vw;
	height: 23%;
	bottom: 36%;
	right: 30%;
	background-image: url("../Assets/Images/Map/Building/Pardubice_St.James_Church_c.png");
}

.Building.CharlesBridge {
	width: 18.5vw;
	height: 16.5%;
	bottom: 48.5%;
	left: 21%;
	background-image: url("../Assets/Images/Map/Building/Prague_Charles_Bridge_c.png");
}

.Building.Rudolfinum {
	width: 17.5vw;
	height: 16.5%;
	top: 21.5%;
	left: 33%;
	background-image: url("../Assets/Images/Map/Building/Prague_Rudolfinum_c.png");
}

.Building.EstatesTheatre {
	width: 9vw;
	height: 14%;
	top: 27%;
	right: 38%;
	background-image: url("../Assets/Images/Map/Building/Prague_The_Estates_Theatre_c.png");
}

.Building.NationalTheatre {
	width: 20vw;
	height: 20%;
	top: 38%;
	right: 40.5%;
	background-image: url("../Assets/Images/Map/Building/Prague_The_National_Theatre_c.png");
}

.Building.Vyšehrad {
	width: 13vw;
	height: 14%;
	bottom: 35.4%;
	left: 31%;
	background-image: url("../Assets/Images/Map/Building/Prague_Vyšehrad_c.png");
}

.Building.DvorakMemorial {
	width: 20vw;
	height: 38%;
	top: 25.5%;
	left: 11.5%;
	background-image: url("../Assets/Images/Map/Building/Příbram_The_Antonín_Dvořák_Memorial_c.png");
}

.Building.AugustinianHouse {
	width: 12vw;
	height: 13%;
	bottom: 31.5%;
	right: 3.2%;
	background-image: url("../Assets/Images/Map/Building/Zlín_Augustinian_House_c.png");
}

@keyframes MahenTheatreTimeFlies {
	0% {
		background-image: url("../Assets/Images/Map/Building/Brno_Mahen_Theatre_day.png");
	}
	50% {
		background-image: url("../Assets/Images/Map/Building/Brno_Mahen_Theatre_night.png");
	}
	100% {
		background-image: url("../Assets/Images/Map/Building/Brno_Mahen_Theatre_day.png");
	}
}

.Sun {
	height: 2vw;
	width: 2vw;
	bottom: 25%;
	right: 28.2%;
	z-index: 10;
	visibility: hidden;
	background-image: url("../Assets/Images/Map/sun.png");
}

.Moon {
	height: 2vw;
	width: 2vw;
	bottom: 25%;
	right: 28.2%;
	z-index: 10;
	visibility: hidden;
	background-image: url("../Assets/Images/Map/moon.png");
}

.Sun {
	visibility: visible;
	animation: SunOrbit linear 8s infinite;
}

.Moon {
	visibility: visible;
	animation: MoonOrbit linear 8s infinite;
}

@keyframes SunOrbit {
	from {
		transform: rotate(0deg) translateX(6.5vw) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translateX(6.5vw) rotate(-360deg);
	}
}

@keyframes MoonOrbit {
	from {
		transform: rotate(0deg) translateX(-6.5vw) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translateX(-6.5vw) rotate(-360deg);
	}
}

.SelectedCity.Brno {
	.City.Brno {
		background-image: url("../Assets/Images/Map/City/Brno_Active.png");
	}

	.Building.OrganistHouse {
		background-image: url("../Assets/Images/Map/Building/Kladno_Organist_House.png");
	}

	.Building.SychrovCastle {
		background-image: url("../Assets/Images/Map/Building/Liberec_Sychrov_Castle.png");
	}

	.Building.MoravianTheatre {
		background-image: url("../Assets/Images/Map/Building/Olomouc_Moravian_Theatre_Olomouc.png");
	}

	.Building.MonumentOfBystrouška {
		background-image: url("../Assets/Images/Map/Building/Ostrava_Monument_of_Bystrouška.png");
	}

	.Building.LitomyšlCastle {
		background-image: url("../Assets/Images/Map/Building/Pardubice_Litomyšl_Castle.png");
	}

	.Building.StJamesChurch {
		background-image: url("../Assets/Images/Map/Building/Pardubice_St.James_Church.png");
	}

	.Building.CharlesBridge {
		background-image: url("../Assets/Images/Map/Building/Prague_Charles_Bridge.png");
	}

	.Building.Rudolfinum {
		background-image: url("../Assets/Images/Map/Building/Prague_Rudolfinum.png");
	}

	.Building.EstatesTheatre {
		background-image: url("../Assets/Images/Map/Building/Prague_The_Estates_Theatre.png");
	}

	.Building.NationalTheatre {
		background-image: url("../Assets/Images/Map/Building/Prague_The_National_Theatre.png");
	}

	.Building.Vyšehrad {
		background-image: url("../Assets/Images/Map/Building/Prague_Vyšehrad.png");
	}

	.Building.DvorakMemorial {
		background-image: url("../Assets/Images/Map/Building/Příbram_The_Antonín_Dvořák_Memorial.png");
	}

	.Building.AugustinianHouse {
		background-image: url("../Assets/Images/Map/Building/Zlín_Augustinian_House.png");
	}
}
.SelectedCity.Kladno {
	.City.Kladno {
		background-image: url("../Assets/Images/Map/City/Kladno_Active.png");
	}

	.Building.JanacekTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Janáček_Theatre.png");
	}

	.Building.MahenTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Mahen_Theatre.png");
	}

	.Building.RedutaTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Reduta_Theatre.png");
	}

	.Building.JanacekHouse {
		background-image: url("../Assets/Images/Map/Building/Brno_The_Leoš_Janáček_House.png");
	}

	.Building.SychrovCastle {
		background-image: url("../Assets/Images/Map/Building/Liberec_Sychrov_Castle.png");
	}

	.Building.MoravianTheatre {
		background-image: url("../Assets/Images/Map/Building/Olomouc_Moravian_Theatre_Olomouc.png");
	}

	.Building.MonumentOfBystrouška {
		background-image: url("../Assets/Images/Map/Building/Ostrava_Monument_of_Bystrouška.png");
	}

	.Building.LitomyšlCastle {
		background-image: url("../Assets/Images/Map/Building/Pardubice_Litomyšl_Castle.png");
	}

	.Building.StJamesChurch {
		background-image: url("../Assets/Images/Map/Building/Pardubice_St.James_Church.png");
	}

	.Building.CharlesBridge {
		background-image: url("../Assets/Images/Map/Building/Prague_Charles_Bridge.png");
	}

	.Building.Rudolfinum {
		background-image: url("../Assets/Images/Map/Building/Prague_Rudolfinum.png");
	}

	.Building.EstatesTheatre {
		background-image: url("../Assets/Images/Map/Building/Prague_The_Estates_Theatre.png");
	}

	.Building.NationalTheatre {
		background-image: url("../Assets/Images/Map/Building/Prague_The_National_Theatre.png");
	}

	.Building.Vyšehrad {
		background-image: url("../Assets/Images/Map/Building/Prague_Vyšehrad.png");
	}

	.Building.DvorakMemorial {
		background-image: url("../Assets/Images/Map/Building/Příbram_The_Antonín_Dvořák_Memorial.png");
	}

	.Building.AugustinianHouse {
		background-image: url("../Assets/Images/Map/Building/Zlín_Augustinian_House.png");
	}
}

.SelectedCity.Liberec {
	.City.Liberec {
		background-image: url("../Assets/Images/Map/City/Liberec_Active.png");
	}

	.Building.JanacekTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Janáček_Theatre.png");
	}

	.Building.MahenTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Mahen_Theatre.png");
	}

	.Building.RedutaTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Reduta_Theatre.png");
	}

	.Building.JanacekHouse {
		background-image: url("../Assets/Images/Map/Building/Brno_The_Leoš_Janáček_House.png");
	}

	.Building.OrganistHouse {
		background-image: url("../Assets/Images/Map/Building/Kladno_Organist_House.png");
	}

	.Building.MoravianTheatre {
		background-image: url("../Assets/Images/Map/Building/Olomouc_Moravian_Theatre_Olomouc.png");
	}

	.Building.MonumentOfBystrouška {
		background-image: url("../Assets/Images/Map/Building/Ostrava_Monument_of_Bystrouška.png");
	}

	.Building.LitomyšlCastle {
		background-image: url("../Assets/Images/Map/Building/Pardubice_Litomyšl_Castle.png");
	}

	.Building.StJamesChurch {
		background-image: url("../Assets/Images/Map/Building/Pardubice_St.James_Church.png");
	}

	.Building.CharlesBridge {
		background-image: url("../Assets/Images/Map/Building/Prague_Charles_Bridge.png");
	}

	.Building.Rudolfinum {
		background-image: url("../Assets/Images/Map/Building/Prague_Rudolfinum.png");
	}

	.Building.EstatesTheatre {
		background-image: url("../Assets/Images/Map/Building/Prague_The_Estates_Theatre.png");
	}

	.Building.NationalTheatre {
		background-image: url("../Assets/Images/Map/Building/Prague_The_National_Theatre.png");
	}

	.Building.Vyšehrad {
		background-image: url("../Assets/Images/Map/Building/Prague_Vyšehrad.png");
	}

	.Building.DvorakMemorial {
		background-image: url("../Assets/Images/Map/Building/Příbram_The_Antonín_Dvořák_Memorial.png");
	}

	.Building.AugustinianHouse {
		background-image: url("../Assets/Images/Map/Building/Zlín_Augustinian_House.png");
	}
}

.SelectedCity.Olomouc {
	.City.Olomouc {
		background-image: url("../Assets/Images/Map/City/Olomouc_Active.png");
	}

	.Building.JanacekTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Janáček_Theatre.png");
	}

	.Building.MahenTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Mahen_Theatre.png");
	}

	.Building.RedutaTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Reduta_Theatre.png");
	}

	.Building.JanacekHouse {
		background-image: url("../Assets/Images/Map/Building/Brno_The_Leoš_Janáček_House.png");
	}

	.Building.OrganistHouse {
		background-image: url("../Assets/Images/Map/Building/Kladno_Organist_House.png");
	}

	.Building.SychrovCastle {
		background-image: url("../Assets/Images/Map/Building/Liberec_Sychrov_Castle.png");
	}

	.Building.MonumentOfBystrouška {
		background-image: url("../Assets/Images/Map/Building/Ostrava_Monument_of_Bystrouška.png");
	}

	.Building.LitomyšlCastle {
		background-image: url("../Assets/Images/Map/Building/Pardubice_Litomyšl_Castle.png");
	}

	.Building.StJamesChurch {
		background-image: url("../Assets/Images/Map/Building/Pardubice_St.James_Church.png");
	}

	.Building.CharlesBridge {
		background-image: url("../Assets/Images/Map/Building/Prague_Charles_Bridge.png");
	}

	.Building.Rudolfinum {
		background-image: url("../Assets/Images/Map/Building/Prague_Rudolfinum.png");
	}

	.Building.EstatesTheatre {
		background-image: url("../Assets/Images/Map/Building/Prague_The_Estates_Theatre.png");
	}

	.Building.NationalTheatre {
		background-image: url("../Assets/Images/Map/Building/Prague_The_National_Theatre.png");
	}

	.Building.Vyšehrad {
		background-image: url("../Assets/Images/Map/Building/Prague_Vyšehrad.png");
	}

	.Building.DvorakMemorial {
		background-image: url("../Assets/Images/Map/Building/Příbram_The_Antonín_Dvořák_Memorial.png");
	}

	.Building.AugustinianHouse {
		background-image: url("../Assets/Images/Map/Building/Zlín_Augustinian_House.png");
	}
}

.SelectedCity.Ostrava {
	.City.Ostrava {
		background-image: url("../Assets/Images/Map/City/Ostrava_Active.png");
	}

	.Building.JanacekTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Janáček_Theatre.png");
	}

	.Building.MahenTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Mahen_Theatre.png");
	}

	.Building.RedutaTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Reduta_Theatre.png");
	}

	.Building.JanacekHouse {
		background-image: url("../Assets/Images/Map/Building/Brno_The_Leoš_Janáček_House.png");
	}

	.Building.OrganistHouse {
		background-image: url("../Assets/Images/Map/Building/Kladno_Organist_House.png");
	}

	.Building.SychrovCastle {
		background-image: url("../Assets/Images/Map/Building/Liberec_Sychrov_Castle.png");
	}

	.Building.MoravianTheatre {
		background-image: url("../Assets/Images/Map/Building/Olomouc_Moravian_Theatre_Olomouc.png");
	}

	.Building.LitomyšlCastle {
		background-image: url("../Assets/Images/Map/Building/Pardubice_Litomyšl_Castle.png");
	}

	.Building.StJamesChurch {
		background-image: url("../Assets/Images/Map/Building/Pardubice_St.James_Church.png");
	}

	.Building.CharlesBridge {
		background-image: url("../Assets/Images/Map/Building/Prague_Charles_Bridge.png");
	}

	.Building.Rudolfinum {
		background-image: url("../Assets/Images/Map/Building/Prague_Rudolfinum.png");
	}

	.Building.EstatesTheatre {
		background-image: url("../Assets/Images/Map/Building/Prague_The_Estates_Theatre.png");
	}

	.Building.NationalTheatre {
		background-image: url("../Assets/Images/Map/Building/Prague_The_National_Theatre.png");
	}

	.Building.Vyšehrad {
		background-image: url("../Assets/Images/Map/Building/Prague_Vyšehrad.png");
	}

	.Building.DvorakMemorial {
		background-image: url("../Assets/Images/Map/Building/Příbram_The_Antonín_Dvořák_Memorial.png");
	}

	.Building.AugustinianHouse {
		background-image: url("../Assets/Images/Map/Building/Zlín_Augustinian_House.png");
	}
}

.SelectedCity.Pardubice {
	.City.Pardubice {
		background-image: url("../Assets/Images/Map/City/Pardubice_Active.png");
	}

	.Building.JanacekTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Janáček_Theatre.png");
	}

	.Building.MahenTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Mahen_Theatre.png");
	}

	.Building.RedutaTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Reduta_Theatre.png");
	}

	.Building.JanacekHouse {
		background-image: url("../Assets/Images/Map/Building/Brno_The_Leoš_Janáček_House.png");
	}

	.Building.OrganistHouse {
		background-image: url("../Assets/Images/Map/Building/Kladno_Organist_House.png");
	}

	.Building.SychrovCastle {
		background-image: url("../Assets/Images/Map/Building/Liberec_Sychrov_Castle.png");
	}

	.Building.MoravianTheatre {
		background-image: url("../Assets/Images/Map/Building/Olomouc_Moravian_Theatre_Olomouc.png");
	}

	.Building.MonumentOfBystrouška {
		background-image: url("../Assets/Images/Map/Building/Ostrava_Monument_of_Bystrouška.png");
	}

	.Building.CharlesBridge {
		background-image: url("../Assets/Images/Map/Building/Prague_Charles_Bridge.png");
	}

	.Building.Rudolfinum {
		background-image: url("../Assets/Images/Map/Building/Prague_Rudolfinum.png");
	}

	.Building.EstatesTheatre {
		background-image: url("../Assets/Images/Map/Building/Prague_The_Estates_Theatre.png");
	}

	.Building.NationalTheatre {
		background-image: url("../Assets/Images/Map/Building/Prague_The_National_Theatre.png");
	}

	.Building.Vyšehrad {
		background-image: url("../Assets/Images/Map/Building/Prague_Vyšehrad.png");
	}

	.Building.DvorakMemorial {
		background-image: url("../Assets/Images/Map/Building/Příbram_The_Antonín_Dvořák_Memorial.png");
	}

	.Building.AugustinianHouse {
		background-image: url("../Assets/Images/Map/Building/Zlín_Augustinian_House.png");
	}
}

.SelectedCity.Prague {
	.City.Prague {
		background-image: url("../Assets/Images/Map/City/Prague_Active.png");
	}

	.Building.JanacekTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Janáček_Theatre.png");
	}

	.Building.MahenTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Mahen_Theatre.png");
	}

	.Building.RedutaTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Reduta_Theatre.png");
	}

	.Building.JanacekHouse {
		background-image: url("../Assets/Images/Map/Building/Brno_The_Leoš_Janáček_House.png");
	}

	.Building.OrganistHouse {
		background-image: url("../Assets/Images/Map/Building/Kladno_Organist_House.png");
	}

	.Building.SychrovCastle {
		background-image: url("../Assets/Images/Map/Building/Liberec_Sychrov_Castle.png");
	}

	.Building.MoravianTheatre {
		background-image: url("../Assets/Images/Map/Building/Olomouc_Moravian_Theatre_Olomouc.png");
	}

	.Building.MonumentOfBystrouška {
		background-image: url("../Assets/Images/Map/Building/Ostrava_Monument_of_Bystrouška.png");
	}

	.Building.LitomyšlCastle {
		background-image: url("../Assets/Images/Map/Building/Pardubice_Litomyšl_Castle.png");
	}

	.Building.StJamesChurch {
		background-image: url("../Assets/Images/Map/Building/Pardubice_St.James_Church.png");
	}

	.Building.DvorakMemorial {
		background-image: url("../Assets/Images/Map/Building/Příbram_The_Antonín_Dvořák_Memorial.png");
	}

	.Building.AugustinianHouse {
		background-image: url("../Assets/Images/Map/Building/Zlín_Augustinian_House.png");
	}
}

.SelectedCity.Příbram {
	.City.Příbram {
		background-image: url("../Assets/Images/Map/City/Příbram_Active.png");
	}

	.Building.JanacekTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Janáček_Theatre.png");
	}

	.Building.MahenTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Mahen_Theatre.png");
	}

	.Building.RedutaTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Reduta_Theatre.png");
	}

	.Building.JanacekHouse {
		background-image: url("../Assets/Images/Map/Building/Brno_The_Leoš_Janáček_House.png");
	}

	.Building.OrganistHouse {
		background-image: url("../Assets/Images/Map/Building/Kladno_Organist_House.png");
	}

	.Building.SychrovCastle {
		background-image: url("../Assets/Images/Map/Building/Liberec_Sychrov_Castle.png");
	}

	.Building.MoravianTheatre {
		background-image: url("../Assets/Images/Map/Building/Olomouc_Moravian_Theatre_Olomouc.png");
	}

	.Building.MonumentOfBystrouška {
		background-image: url("../Assets/Images/Map/Building/Ostrava_Monument_of_Bystrouška.png");
	}

	.Building.LitomyšlCastle {
		background-image: url("../Assets/Images/Map/Building/Pardubice_Litomyšl_Castle.png");
	}

	.Building.StJamesChurch {
		background-image: url("../Assets/Images/Map/Building/Pardubice_St.James_Church.png");
	}

	.Building.CharlesBridge {
		background-image: url("../Assets/Images/Map/Building/Prague_Charles_Bridge.png");
	}

	.Building.Rudolfinum {
		background-image: url("../Assets/Images/Map/Building/Prague_Rudolfinum.png");
	}

	.Building.EstatesTheatre {
		background-image: url("../Assets/Images/Map/Building/Prague_The_Estates_Theatre.png");
	}

	.Building.NationalTheatre {
		background-image: url("../Assets/Images/Map/Building/Prague_The_National_Theatre.png");
	}

	.Building.Vyšehrad {
		background-image: url("../Assets/Images/Map/Building/Prague_Vyšehrad.png");
	}

	.Building.AugustinianHouse {
		background-image: url("../Assets/Images/Map/Building/Zlín_Augustinian_House.png");
	}
}

.SelectedCity.Zlín {
	.City.Zlín {
		background-image: url("../Assets/Images/Map/City/Zlín_Active.png");
	}

	.Building.JanacekTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Janáček_Theatre.png");
	}

	.Building.MahenTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Mahen_Theatre.png");
	}

	.Building.RedutaTheatre {
		background-image: url("../Assets/Images/Map/Building/Brno_Reduta_Theatre.png");
	}

	.Building.JanacekHouse {
		background-image: url("../Assets/Images/Map/Building/Brno_The_Leoš_Janáček_House.png");
	}

	.Building.OrganistHouse {
		background-image: url("../Assets/Images/Map/Building/Kladno_Organist_House.png");
	}

	.Building.SychrovCastle {
		background-image: url("../Assets/Images/Map/Building/Liberec_Sychrov_Castle.png");
	}

	.Building.MoravianTheatre {
		background-image: url("../Assets/Images/Map/Building/Olomouc_Moravian_Theatre_Olomouc.png");
	}

	.Building.MonumentOfBystrouška {
		background-image: url("../Assets/Images/Map/Building/Ostrava_Monument_of_Bystrouška.png");
	}

	.Building.LitomyšlCastle {
		background-image: url("../Assets/Images/Map/Building/Pardubice_Litomyšl_Castle.png");
	}

	.Building.StJamesChurch {
		background-image: url("../Assets/Images/Map/Building/Pardubice_St.James_Church.png");
	}

	.Building.CharlesBridge {
		background-image: url("../Assets/Images/Map/Building/Prague_Charles_Bridge.png");
	}

	.Building.Rudolfinum {
		background-image: url("../Assets/Images/Map/Building/Prague_Rudolfinum.png");
	}

	.Building.EstatesTheatre {
		background-image: url("../Assets/Images/Map/Building/Prague_The_Estates_Theatre.png");
	}

	.Building.NationalTheatre {
		background-image: url("../Assets/Images/Map/Building/Prague_The_National_Theatre.png");
	}

	.Building.Vyšehrad {
		background-image: url("../Assets/Images/Map/Building/Prague_Vyšehrad.png");
	}

	.Building.DvorakMemorial {
		background-image: url("../Assets/Images/Map/Building/Příbram_The_Antonín_Dvořák_Memorial.png");
	}
}

.Character {
	transition: opacity 0.5s;
	background-size: contain;
	background-repeat: no-repeat;
}

.Character.Dvorak {
	width: 5vw;
	height: 10%;
	bottom: 40%;
	left: 11.5%;
	background-image: url("../Assets/Images/Map/Composer/Dvorak_mini.png");
}

.Character.Janacek {
	width: 5vw;
	height: 7.5%;
	bottom: 28%;
	left: 37.5%;
	background-image: url("../Assets/Images/Map/Composer/Janacek_mini.png");
}

.Character.Martinu {
	width: 5vw;
	height: 7%;
	top: 54%;
	right: 24.5%;
	background-image: url("../Assets/Images/Map/Composer/Martinu_mini.png");
}

.Character.Smetana {
	width: 5vw;
	height: 6%;
	top: 36%;
	left: 33%;
	background-image: url("../Assets/Images/Map/Composer/Smetana_mini.png");
}

.CharacterGIF {
	z-index: 99;
	position: absolute;
	transition: all 0.5s;
	visibility: hidden;

	img {
		max-width: 100%;
		max-height: 100%;
	}
}

.CharacterGIF.Janacek {
	width: 2.5vw;
	height: 4vw;
	right: 60%;
	bottom: 30%;

	img {
		transform: rotateY(180deg);
	}
}

.CharacterGIF.Smetana {
	width: 3vw;
	height: 4.3vw;
	left: 30%;
	bottom: 58%;

	img {
		transform: rotateY(180deg);
	}
}

.CharacterGIF.Martinu {
	width: 2.5vw;
	height: 4.2vw;
	top: 35.5%;
	right: 34%;
}

.CharacterGIF.Dvorak {
	width: 2.5vw;
	height: 4vw;
	bottom: 42%;
	left: 14.5%;
}

.MiniCharacter {
	z-index: 20;
	position: absolute;
	background-repeat: no-repeat;
	background-size: contain;
	visibility: hidden;
}

.MiniCharacter.Janacek.sit {
	width: 5vw;
	height: 6.5%;
	bottom: 29%;
	left: 34.5%;
	background-image: url("../Assets/Images/Map/Composer/Janacek_mini_sit.png");
}

.MiniCharacter.Janacek.stand {
	width: 6vw;
	height: 6.5%;
	bottom: 29%;
	left: 34.5%;
	background-image: url("../Assets/Images/Map/Composer/Janacek_mini_stand.png");
}

.MiniCharacter.Smetana.sit {
	width: 5vw;
	height: 4vw;
	bottom: 58%;
	left: 33%;
	background-image: url("../Assets/Images/Map/Composer/Smetana_mini_sit.png");
}

.MiniCharacter.Smetana.stand {
	width: 6vw;
	height: 3.8vw;
	bottom: 58.5%;
	left: 31%;
	background-image: url("../Assets/Images/Map/Composer/Smetana_mini_stand.png");
}

.MiniCharacter.Martinu.sit {
	width: 5vw;
	height: 3.7vw;
	top: 53.5%;
	right: 24.5%;
	background-image: url("../Assets/Images/Map/Composer/Martinu_mini_sit.png");
}

.MiniCharacter.Martinu.stand {
	width: 5.5vw;
	height: 4.1vw;
	top: 53%;
	right: 25.4%;
	background-image: url("../Assets/Images/Map/Composer/Martinu_mini_stand.png");
}

.MiniCharacter.Dvorak.sit {
	width: 7vw;
	height: 12%;
	bottom: 38%;
	left: 11.5%;
	background-image: url("../Assets/Images/Map/Composer/Dvorak_mini_sit.png");
}

.MiniCharacter.Dvorak.stand {
	width: 5vw;
	height: 10%;
	bottom: 41.7%;
	left: 11.5%;
	background-image: url("../Assets/Images/Map/Composer/Dvorak_mini_stand.png");
}

@keyframes Stand {
	73% {
		visibility: hidden;
	}
	74% {
		visibility: visible;
	}
	83% {
		visibility: visible;
	}
	84% {
		visibility: hidden;
	}
}

@keyframes Sit {
	84% {
		visibility: hidden;
	}
	85% {
		visibility: visible;
	}
}

// .ComposerHovering.Dvorak {
.Character.Dvorak {
	opacity: 0;
}

.CharacterGIF.Dvorak {
	animation: DvorakMoving 8s linear infinite;
}

.MiniCharacter.Dvorak.stand {
	animation: Stand 8s linear infinite;
}

.MiniCharacter.Dvorak.sit {
	animation: Sit 8s linear infinite;
}
// }

@keyframes DvorakMoving {
	0% {
		bottom: 38%;
		left: 28%;
		transform: rotateY(180deg);
	}
	45% {
		bottom: 38%;
		left: 16%;
	}
	56% {
		bottom: 43.5%;
		left: 14.5%;
	}
	72% {
		visibility: visible;
	}
	73% {
		bottom: 43.5%;
		left: 14.5%;
		visibility: hidden;
		transform: rotateY(180deg);
	}
}

// .ComposerHovering.Janacek {
.Character.Janacek {
	opacity: 0;
}

.CharacterGIF.Janacek {
	animation: JanacekMoving 8s linear infinite;
}

.MiniCharacter.Janacek.stand {
	animation: Stand 8s linear infinite;
}

.MiniCharacter.Janacek.sit {
	animation: Sit 8s linear infinite;
}
// }

@keyframes JanacekMoving {
	0% {
		right: 40%;
		bottom: 24%;
	}
	34% {
		bottom: 24%;
	}
	44% {
		right: 54%;
		bottom: 28%;
	}
	62% {
		right: 60%;
		bottom: 28.5%;
		transform: rotateY(0deg);
	}
	68% {
		transform: rotateY(180deg);
	}
	72% {
		visibility: visible;
	}
	73% {
		visibility: hidden;
		transform: rotateY(180deg);
		right: 60%;
		bottom: 28.5%;
	}
}

// .ComposerHovering.Martinu {
.Character.Martinu {
	opacity: 0;
}

.CharacterGIF.Martinu {
	animation: MartinuMoving 4s linear infinite;
}

.MiniCharacter.Martinu.stand {
	animation: Stand 4s linear infinite;
}

.MiniCharacter.Martinu.sit {
	animation: Sit 4s linear infinite;
}
// }

@keyframes MartinuMoving {
	0% {
		top: 55%;
		right: 40%;
		transform: rotateY(180deg);
	}
	45% {
		top: 55%;
		right: 32%;
	}
	66% {
		top: 53%;
		right: 29%;
	}
	72% {
		visibility: visible;
	}
	73% {
		visibility: hidden;
		top: 53%;
		right: 29%;
		transform: rotateY(180deg);
	}
}

// .ComposerHovering.Smetana {
.Character.Smetana {
	opacity: 0;
}

.CharacterGIF.Smetana {
	animation: SmetanaMoving 9s linear infinite;
}

.MiniCharacter.Smetana.stand {
	animation: Stand 9s linear infinite;
}

.MiniCharacter.Smetana.sit {
	animation: Sit 9s linear infinite;
}
// }

@keyframes SmetanaMoving {
	0% {
		left: 60%;
		bottom: 60%;
		visibility: visible;
		transform: rotateY(180deg);
	}
	64% {
		left: 32%;
		bottom: 60%;
	}
	68% {
		bottom: 58%;
		left: 30.5%;
	}
	72% {
		visibility: visible;
	}
	73% {
		visibility: hidden;
		transform: rotateY(180deg);
	}
}

.WhiteMask {
	z-index: 100;
	background-color: #ffffff;
	position: absolute;
	top: 0;
	height: 100%;
	width: 100vw;
	opacity: 0.8;
}
.DotsRect,
.MaskLogo,
.MaskSubtitle,
.MaskTitle {
	transition: all 1.5s;
}

.TransMask {
	z-index: 100;
	position: absolute;
	top: 0;
	height: 100%;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;

	.MaskBtn {
		background-image: url("../Assets/Images/Chapter/Slider/Arrow.png");
		background-repeat: no-repeat;
		background-size: contain;
		width: 1.5vw;
		height: 1.5vw;
		transform: rotate(-90deg);
		cursor: pointer;
		transition: all 1s;
	}

	.MaskOne {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		flex-direction: column;
		transition: all 1s;
		z-index: 200;

		.MaskTitle {
			p {
				font-family: adorn-coronet, sans-serif;
				font-size: 7vw;
				color: #192d42;
			}
		}

		.MaskLogo {
			background-image: url("../Assets/Images/Map/MaskLogo.png");
			background-repeat: no-repeat;
			background-size: contain;
			width: 17vw;
			height: calc(var(--heightRatio) * 7.5vw);
		}
	}

	.MaskTwo {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		flex-direction: column;
		position: absolute;
		width: 100vw;
		height: 100%;
		z-index: 150;
		opacity: 0;
		transition: all 1s;

		h1 {
			font-family: adorn-coronet, sans-serif;
			font-size: 6vw;
			font-weight: 100;
			line-height: 8vw;
			margin: 0;
			color: #192d42;
		}

		p {
			line-height: calc(var(--heightRatio) * 3vw);
			font-size: 1vw;
			width: 40vw;
			text-align: justify;
			text-justify: inter-character;
		}
	}
}

.TransMask.Two {
	.MaskOne {
		opacity: 0;
		z-index: -10;

		.MaskTitle {
			opacity: 0;
			filter: blur(1rem);
		}

		.MaskTitle {
			transform: scale(2, 1);
		}
	}

	.MaskTwo {
		opacity: 1;
		z-index: 0;
	}
}

.TransMask.Sliding {
	.DotsRect,
	.MaskLogo,
	.MaskSubtitle,
	.MaskTwo {
		opacity: 0;
	}

	.MaskTitle {
		opacity: 0;
		filter: blur(1rem);
	}

	.MaskTitle {
		transform: scale(2, 1);
	}

	.MaskBtn {
		opacity: 0;
	}
}

.TransMask.Slided {
	z-index: -100;
}

.ShareExp {
	position: absolute;
	top: 0;
	width: 100vw;
	height: 100vh;
	z-index: 200;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	p {
		color: #ffffff;
		font-size: 2vw;
		margin: 0.5vw;
	}

	.MusicJourney {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 80vw;

		.Journey {
			background-repeat: no-repeat;
			background-size: contain;
			width: 15vw;
			height: 15vw;
			cursor: pointer;
			margin-top: 2.5vw;
			margin-bottom: 2.5vw;
		}

		.Journey:hover {
			transform: scale(1.05);
		}

		.Journey.SmetanaImg {
			background-image: url("../Assets/Images/ShareExp/smetana.png");
		}

		.Journey.DvorakImg {
			background-image: url("../Assets/Images/ShareExp/dvorak.png");
		}

		.Journey.JanacekImg {
			background-image: url("../Assets/Images/ShareExp/janacek.png");
		}

		.Journey.MartinuImg {
			background-image: url("../Assets/Images/ShareExp/martinu.png");
		}
	}

	.CloseBtn {
		cursor: pointer;
		width: 2vw;
		height: 2vw;
		background-image: url("../Assets/Images/ShareExp/Close.png");
		background-repeat: no-repeat;
		background-size: contain;
	}
}

.ShareExp.Invisible {
	z-index: -300;
}

.TutorialOne,
.TutorialTwo {
	padding: 0vw 1vw;
	border-radius: 1vw;
	background-color: rgba(255, 255, 255, 0.8);
	position: absolute;
	z-index: 20;
	cursor: pointer;
	transition: all 0.5s;
	border-color: #000000;
	border-width: 0.5vw;

	p {
		font-size: 1vw;
	}
}

.TutorialOne {
	width: 15.5vw;
	left: 40%;
	top: 27%;
}

.TutorialTwo {
	width: 21vw;
	left: 1.5%;
	bottom: 28%;
}

.TutorialOne.Readed,
.TutorialTwo.Readed {
	opacity: 0;
	z-index: -300;
}

@media only screen and (max-width: 450px) {
	.Map.ZH-HANT {
		.TransMask {
			.MaskOne {
				.MaskTitle {
					p {
						font-size: 17vw !important;
					}
				}
			}

			.MaskTwo {
				h1 {
					font-size: 10vw !important;
				}
			}
		}

		.TutorialOne {
			width: 45vw !important;
			left: 42% !important;

			p {
				font-size: 3.75vw !important;
				line-height: 6vw !important;
			}
		}

		.TutorialTwo {
			width: 60vw !important;
			left: 5% !important;

			p {
				font-size: 3.75vw !important;
				line-height: 6vw !important;
			}
		}
	}

	.Map {
		#BackgroundMap,
		#BackgroundContainer {
			left: -110vw;
		}

		.TransMask {
			.MaskBtn {
				width: 6vw !important;
				height: 6vw !important;
			}

			.MaskOne {
				.MaskTitle {
					p {
						font-size: 20vw !important;
						line-height: 20vw;
						text-align: center;
						white-space: pre-line;
						margin: 5vh 0vh;
					}
				}

				.MaskLogo {
					width: 48vw !important;
					height: 13vw !important;
				}
			}

			.MaskTwo {
				h1 {
					font-size: 18vw !important;
					line-height: 20vw !important;
				}

				p {
					font-size: 3.75vw !important;
					line-height: 6vw !important;
					width: 80vw !important;
				}
			}
		}

		.LeftFooter {
			position: absolute;
			left: 30px !important;
			bottom: 40px !important;

			p {
				font-family: adorn-coronet, sans-serif;
				font-size: 1.5vw;
				color: #192d42;
			}
		}

		.TutorialOne {
			width: 45vw !important;
			left: 42% !important;

			p {
				font-size: 3.75vw !important;
				line-height: 6vw !important;
			}
		}

		.TutorialTwo {
			width: 60vw !important;
			left: 5% !important;

			p {
				font-size: 3.75vw !important;
				line-height: 6vw !important;
			}
		}
	}
}

@media only screen and (max-width: 1366px) {
	.Background {
		width: 1366px !important;
		cursor: grab;
	}

	.KeepSelected {
		width: calc(1366 * 0.8px) !important;
		height: calc(1366 * var(--mapRatio) * 0.8px) !important;
	}

	.Composer {
		width: calc(1366 * 0.1px) !important;
		height: calc(1366 * var(--mapRatio) * 0.25px) !important;
	}

	.Composer.Janacek {
		width: calc(1366 * 0.13px) !important;
		height: calc(1366 * var(--mapRatio) * 0.28px) !important;
	}

	.Composer.Martinu {
		width: calc(1366 * 0.135px) !important;
		height: calc(1366 * var(--mapRatio) * 0.29px) !important;
	}

	.Composer.Smetana {
		width: calc(1366 * 0.12px) !important;
		height: calc(1366 * var(--mapRatio) * 0.25px) !important;
	}

	.City {
		height: calc(1366 * var(--mapRatio) * 0.05px) !important;
	}

	.City.Brno {
		width: calc(1366 * 0.036px) !important;
	}

	.City.Kladno {
		width: calc(1366 * 0.045px) !important;
	}

	.City.Liberec {
		width: calc(1366 * 0.05px) !important;
	}

	.City.Olomouc {
		width: calc(1366 * 0.06px) !important;
	}

	.City.Ostrava {
		width: calc(1366 * 0.06px) !important;
	}

	.City.Pardubice {
		width: calc(1366 * 0.07px) !important;
	}

	.City.Prague {
		width: calc(1366 * 0.045px) !important;
	}

	.City.Příbram {
		width: calc(1366 * 0.055px) !important;
	}

	.City.Zlín {
		width: calc(1366 * 0.04px) !important;
	}

	.Building.JanacekTheatre {
		width: calc(1366 * 0.125px) !important;
		height: calc(1366 * var(--mapRatio) * 0.125px) !important;
	}

	.Building.MahenTheatre {
		width: calc(1366 * 0.25px) !important;
		height: calc(1366 * 0.2px) !important;
	}

	.Building.RedutaTheatre {
		width: calc(1366 * 0.165px) !important;
		height: calc(1366 * var(--mapRatio) * 0.15px) !important;
	}

	.Building.JanacekHouse {
		width: calc(1366 * 0.14px) !important;
		height: calc(1366 * var(--mapRatio) * 0.145px) !important;
	}

	.Building.OrganistHouse {
		width: calc(1366 * 0.14px) !important;
		height: calc(1366 * var(--mapRatio) * 0.15px) !important;
	}

	.Building.SychrovCastle {
		width: calc(1366 * 0.18px) !important;
		height: calc(1366 * var(--mapRatio) * 0.15px) !important;
	}

	.Building.MoravianTheatre {
		width: calc(1366 * 0.07px) !important;
		height: calc(1366 * var(--mapRatio) * 0.12px) !important;
	}

	.Building.MonumentOfBystrouška {
		width: calc(1366 * 0.08px) !important;
		height: calc(1366 * var(--mapRatio) * 0.14px) !important;
	}

	.Building.LitomyšlCastle {
		width: calc(1366 * 0.11px) !important;
		height: calc(1366 * var(--mapRatio) * 0.15px) !important;
	}

	.Building.StJamesChurch {
		width: calc(1366 * 0.12px) !important;
		height: calc(1366 * var(--mapRatio) * 0.23px) !important;
	}

	.Building.CharlesBridge {
		width: calc(1366 * 0.185px) !important;
		height: calc(1366 * var(--mapRatio) * 0.165px) !important;
	}

	.Building.Rudolfinum {
		width: calc(1366 * 0.175px) !important;
		height: calc(1366 * var(--mapRatio) * 0.165px) !important;
	}

	.Building.EstatesTheatre {
		width: calc(1366 * 0.09px) !important;
		height: calc(1366 * var(--mapRatio) * 0.14px) !important;
	}

	.Building.NationalTheatre {
		width: calc(1366 * 0.2px) !important;
		height: calc(1366 * var(--mapRatio) * 0.2px) !important;
	}

	.Building.Vyšehrad {
		width: calc(1366 * 0.13px) !important;
		height: calc(1366 * var(--mapRatio) * 0.14px) !important;
	}

	.Building.DvorakMemorial {
		width: calc(1366 * 0.2px) !important;
		height: calc(1366 * var(--mapRatio) * 0.38px) !important;
	}

	.Building.AugustinianHouse {
		width: calc(1366 * 0.12px) !important;
		height: calc(1366 * var(--mapRatio) * 0.13px) !important;
	}

	.Sun,
	.Moon {
		width: calc(1366 * 0.02px) !important;
		height: calc(1366 * 0.02px) !important;
	}

	.Character.Dvorak {
		width: calc(1366 * 0.05px) !important;
		height: calc(1366 * var(--mapRatio) * 0.1px) !important;
	}

	.Character.Janacek {
		width: calc(1366 * 0.05px) !important;
		height: calc(1366 * var(--mapRatio) * 0.075px) !important;
	}

	.Character.Martinu {
		width: calc(1366 * 0.05px) !important;
		height: calc(1366 * var(--mapRatio) * 0.07px) !important;
	}

	.Character.Smetana {
		width: calc(1366 * 0.05px) !important;
		height: calc(1366 * var(--mapRatio) * 0.06px) !important;
	}

	.CharacterGIF.Janacek {
		width: calc(1366 * 0.025px) !important;
		height: calc(1366 * 0.04px) !important;
	}

	.CharacterGIF.Smetana {
		width: calc(1366 * 0.03px) !important;
		height: calc(1366 * 0.043px) !important;
	}

	.CharacterGIF.Martinu {
		width: calc(1366 * 0.025px) !important;
		height: calc(1366 * 0.042px) !important;
	}

	.CharacterGIF.Dvorak {
		width: calc(1366 * 0.025px) !important;
		height: calc(1366 * 0.04px) !important;
	}

	.MiniCharacter.Janacek.sit {
		width: calc(1366 * 0.05px) !important;
		height: calc(1366 * var(--mapRatio) * 0.065px) !important;
	}

	.MiniCharacter.Janacek.stand {
		width: calc(1366 * 0.06px) !important;
		height: calc(1366 * var(--mapRatio) * 0.065px) !important;
	}

	.MiniCharacter.Smetana.sit {
		width: calc(1366 * 0.05px) !important;
		height: calc(1366 * 0.04px) !important;
	}

	.MiniCharacter.Smetana.stand {
		width: calc(1366 * 0.06px) !important;
		height: calc(1366 * 0.038px) !important;
	}

	.MiniCharacter.Martinu.sit {
		width: calc(1366 * 0.05px) !important;
		height: calc(1366 * 0.037px) !important;
	}

	.MiniCharacter.Martinu.stand {
		width: calc(1366 * 0.055px) !important;
		height: calc(1366 * 0.041px) !important;
	}

	.MiniCharacter.Dvorak.sit {
		width: calc(1366 * 0.07px) !important;
		height: calc(1366 * var(--mapRatio) * 0.12px) !important;
	}

	.MiniCharacter.Dvorak.stand {
		width: calc(1366 * 0.05px) !important;
		height: calc(1366 * var(--mapRatio) * 0.1px) !important;
	}

	.LeftFooter p {
		font-size: calc(1366 * 0.02px) !important;
	}

	.ShareExp {
		p {
			font-size: 3.75vw !important;
			margin: 3vw !important;
		}

		.MusicJourney {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 80vw;
		}

		.CloseBtn {
			cursor: pointer;
			width: 2vw;
			height: 2vw;
			background-image: url("../Assets/Images/ShareExp/Close.png");
			background-repeat: no-repeat;
			background-size: contain;
		}
	}
}
