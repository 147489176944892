.FooterContainer {
	width: 100%;
	height: 15vw;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	position: relative;
}

.FooterPrev,
.FooterNext {
	height: 100%;
	width: 75%;
	position: absolute;
	top: 0;
	background-color: antiquewhite;
	transition: transform 0.5s cubic-bezier(0.49, 0.48, 0.6, 1),
		background-color 0.5s cubic-bezier(0.49, 0.48, 0.6, 1);
	cursor: pointer;
}

.FooterPrev {
	left: 0;
}

.FooterNext {
	left: 50%;
	border-left: 1px solid #c6c4c3;
}

.FooterPrev h1,
.FooterNext h1 {
	position: absolute;
	left: 10%;
	transition: color 0.5s cubic-bezier(0.49, 0.48, 0.6, 1);
}

.FooterContainer.left .FooterPrev {
	background-color: darkslateblue;
}

.FooterContainer.left .FooterNext {
	transform: translateX(25%);
}

.FooterContainer.right .FooterNext {
	transform: translateX(-25%);
	background-color: darkslateblue;
}

.FooterContainer.left .FooterPrev h1 {
	color: #ffffff;
}

.FooterContainer.right .FooterNext h1 {
	color: #ffffff;
}

// .Tooltip {
//   position: absolute;
//   background-color: #456990;
//   width: 20%;
//   height: auto;
//   z-index: -100;
//   opacity: 0;
//   transition: all 0.5s;

//   div::after {
//     content: "";
//     position: absolute;
//     bottom: -16px;
//     left: 50%;
//     margin-left: -20px;
//     width: 0;
//     height: 0;
//     border-left: 20px solid transparent;
//     border-right: 20px solid transparent;
//     border-top: 20px solid #456990;
//   }

//   p {
//     color: #ffffff;
//     padding: 2.5% 5%;
//   }
// }

// .Tooltip .tooltipBtn {
// 	cursor: pointer;
// }
