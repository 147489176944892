// @import "./Chapter.scss";

.NDB.ZH-HANT {
  .Section.One {
    cursor: url("../Assets/Images/ClickCursor_ZH.png"), pointer;
  }

  .TheatreTitle p {
    font-family: yu-mincho-pr6, sans-serif !important;
    font-size: 4.5vw !important;
  }

  .TimelineEvent h1 {
    font-size: 4vw !important;
  }

  .TimelineEvent.One {
    top: calc(var(--heightRatio) * 58vw) !important;
  }

  .TimelineEvent.Two {
    top: calc(var(--heightRatio) * 97.5vw) !important;
  }

  .TimelineEvent.Three {
    top: calc(var(--heightRatio) * 136vw) !important;
  }

  .TimelineEvent.Four {
    top: calc(var(--heightRatio) * 175vw) !important;
  }

  .TimelineEvent.Five {
    top: calc(var(--heightRatio) * 214vw) !important;
  }

  .TimelineEvent.Six {
    top: calc(var(--heightRatio) * 253vw) !important;
  }

  .TimelineEvent.Seven {
    top: calc(var(--heightRatio) * 292vw) !important;
  }

  .Section.ThreePointTwo {
    .TheatrenaVeveříTitle.ZH p {
      font-size: 2.5vw;
    }

    .Quote.One {
      font-size: 1.5vw !important;
      line-height: 1.75vw !important;
      font-weight: 100 !important;
    }
  }

  .MapRedirect p {
    font-weight: 600 !important;
    font-size: 2vw !important;
  }

  .Section.Eleven .Paragraph.Five {
    width: 29vw !important;

    p {
      text-align: justify !important;
    }
  }
}

.NDB {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url("../Assets/Images/NDB/Section3/TheatrenaVeveří.png");
  background-size: cover;
  background-position: center;
  width: 100vw;

  .TheatreTitle.ZH {
    background-image: initial !important;

    p {
      font-family: adorn-coronet, sans-serif;
      font-size: 4.5vw;
    }
  }

  .Section.One {
    cursor: url("../Assets/Images/ClickCursor.png"), pointer;

    .ChapterName {
      width: 42vw;
      right: 22vw;
      top: 43.6%;
    }

    .Paragraph.One {
      width: 38vw;
      right: 15vw;
      top: 59%;
      opacity: 0;
      transition: all 1s;
    }

    .Paragraph.One.Visible {
      opacity: 1;
      top: 56%;
    }
  }

  .Section.Two {
    background-color: #ffffff;
    // height: calc(var(--heightRatio) * 200vw);
    height: 200vh;

    .ScrollMagicWrapper {
      .TheatreTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        // height: calc(var(--heightRatio) * 100vw);
        height: 50vh;
        position: absolute;
        transition: opacity 1s;

        p {
          font-family: adorn-coronet, sans-serif;
          font-size: 5.5vw;
        }
      }

      .RedutaTheatrePhoto {
        background-image: url("../Assets/Images/NDB/Section2/RedutaTheatre.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100vw;
        // height: calc(var(--heightRatio) * 100vw);
        height: 100vh;
        position: absolute;
        z-index: -10;
      }
    }

    .ScrollMagicWrapper.Stage2 {
      .TheatreTitle {
        opacity: 0;
        z-index: -100;
      }

      .RedutaTheatrePhoto {
        transition: opacity 1s;
        opacity: 1 !important;
      }
    }
  }

  .Section.Three {
    width: 100vw;
    height: calc(var(--heightRatio) * 350vw);
    overflow: hidden;

    .Ellipse.Green {
      background-image: url("../Assets/Images/Chapter/Section2/GreenEllipse.png");
      width: 40vw;
      height: 40vw;
      top: calc(var(--heightRatio) * 140vw);
      right: -6vw;
    }

    .Paragraph.Two {
      width: 45vw;
      left: 27.5vw;
      top: calc(var(--heightRatio) * 20vw);
    }

    .BGDots.RightDots {
      width: 20vw;
      height: 40vw;
      right: -1vw;
      top: calc(var(--heightRatio) * 180vw);
    }

    .Timeline {
      background-image: url("../Assets/Images/NDB/Section3/Timeline.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 5vw;
      height: calc(var(--heightRatio) * 280vw);
      position: absolute;
      left: 15vw;
      top: calc(var(--heightRatio) * 60vw);
    }

    .TimelineEvent {
      position: absolute;
      left: 25vw;

      h1 {
        font-family: adorn-coronet, sans-serif;
        font-size: 5.5vw;
        font-weight: 400;
        color: #192d42;
        margin: 0;
      }

      p {
        font-family: acumin-pro, sans-serif;
        width: 35vw;
        margin: 0;
      }
    }

    .TimelineEvent.One {
      top: calc(var(--heightRatio) * 53vw);
    }

    .TimelineEvent.Two {
      top: calc(var(--heightRatio) * 92.5vw);
    }

    .TimelineEvent.Three {
      top: calc(var(--heightRatio) * 131vw);
    }

    .TimelineEvent.Four {
      top: calc(var(--heightRatio) * 170vw);
    }

    .TimelineEvent.Five {
      top: calc(var(--heightRatio) * 209vw);
    }

    .TimelineEvent.Six {
      top: calc(var(--heightRatio) * 248vw);
    }

    .TimelineEvent.Seven {
      top: calc(var(--heightRatio) * 287vw);
    }
  }

  .Section.ThreePointOne {
    background-color: transparent;
  }

  .Section.ThreePointTwo {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    .TheatrenaVeveříTitle {
      width: 20vw;
      height: 3.5vw;
      margin-bottom: 1vw;
      background-image: url("../Assets/Images/NDB/Section3/TheatrenaVeveříTitle.png");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .TheatrenaVeveříTitle.ZH {
      background-image: initial;
      width: auto;

      p {
        color: #ffffff;
        font-family: yu-mincho-pr6, sans-serif !important;
        font-weight: 600;
        font-size: 2vw;
        margin: 0;
        width: auto;
      }
    }

    h1 {
      color: #ffffff;
      font-size: 2vw;
      font-weight: 600;
      line-height: 2.5vw;
    }

    p {
      color: #ffffff;
      line-height: 1.75vw;
      font-size: 1.25vw;
      width: 40vw;
      margin: 0;
      padding-top: 1vw;
      padding-bottom: 1vw;
    }

    .Quote.One {
      position: static;
      font-family: adorn-coronet, sans-serif;
      font-size: 2.5vw;
      line-height: 2.5vw;
      color: #ffffff;
    }
  }

  .Section.Four {
    background-color: #ffffff;
    // height: calc(var(--heightRatio) * 200vw);
    height: 200vh;

    .ScrollMagicWrapper {
      .TheatreTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        // height: calc(var(--heightRatio) * 100vw);
        height: 50vh;
        position: absolute;
        transition: all 1s;

        p {
          font-family: adorn-coronet, sans-serif;
          font-size: 5.5vw;
        }
      }

      .MahenTheatrePhoto {
        background-image: url("../Assets/Images/NDB/Section4/MahenTheatre.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100vw;
        // height: calc(var(--heightRatio) * 100vw);
        height: 100vh;
        position: absolute;
        z-index: -10;
        opacity: 0.5;
      }
    }

    .ScrollMagicWrapper.Stage2 {
      .TheatreTitle {
        opacity: 0;
        z-index: -100;
      }

      .MahenTheatrePhoto {
        transition: opacity 1s;
        opacity: 1 !important;
      }
    }
  }

  .Section.Five {
    height: calc(var(--heightRatio) * 50vw);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fdf5eb;
    overflow: hidden;

    .Ellipse.Green {
      background-image: url("../Assets/Images/Chapter/Section2/GreenEllipse.png");
      width: 25vw;
      height: 25vw;
      top: calc(var(--heightRatio) * 530vw);
      right: -5vw;
    }

    .Ellipse.Blue {
      background-image: url("../Assets/Images/Chapter/Section2/BlueEllipse.png");
      width: 70vw;
      height: 70vw;
      top: calc(var(--heightRatio) * 535vw);
      left: -10vw;
    }

    .Paragraph.Four {
      position: relative;
      width: 50vw;
    }
  }

  .Section.Six {
    width: 150vw;
    // height: calc(var(--heightRatio) * 100 + 50vw);
    height: 200vh;

    .StaircaseBG {
      width: 100vw;
      // height: calc(var(--heightRatio) * 100vw);
      height: calc(100vh + 50vw);
      background-color: #fdf5eb;
    }

    .Staircase {
      width: 150vw;
      // height: calc(var(--heightRatio) * 100vw);
      height: calc(100vh + 50vw);
      position: absolute;
      top: 0;
      display: flex;

      .Images.StaircasePhoto {
        width: 100vw;
        height: 100vh;
      }

      .StaircasePhotoInfo {
        width: 50vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          width: 25vw;
        }
      }
    }
  }

  .Section.Seven {
    height: 200vh;
    overflow: hidden;

    .StageBG {
      width: 100vw;
      height: calc(100vh + 50vw);
      background-color: #fdf5eb;
    }

    .Stage {
      width: 150vw;
      height: calc(100vh + 50vw);
      position: absolute;
      top: 0;
      display: flex;

      .Images.StagePhoto {
        width: 100vw;
        height: 100vh;
      }

      .StagePhotoInfo {
        width: 50vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          width: 25vw;
        }
      }
    }
  }

  .Section.Eight {
    background-color: #ffffff;
    // height: calc(var(--heightRatio) * 200vw);
    height: 200vh;
    overflow: hidden;

    .TheatreTitleContainer {
      width: 100vw;
      // height: calc(var(--heightRatio) * 100vw);
      height: 50vh;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      transition: opacity 1s;

      .TheatreTitle {
        background-image: url("../Assets/Images/NDB/Section8/JanáčekTheatreTitle.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: 30vw;
        height: 6vw;
      }
    }

    .JanáčekTheatrePhoto {
      background-image: url("../Assets/Images/NDB/Section8/JanáčekTheatre.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100vw;
      // height: calc(var(--heightRatio) * 100vw);
      height: 100vh;
      position: absolute;
      z-index: -10;
      opacity: 0.5;
      transition: opacity 1s;
    }

    .ScrollMagicWrapper.Stage2 {
      .TheatreTitleContainer {
        opacity: 0;
        z-index: -100;
      }

      .JanáčekTheatrePhoto {
        transition: opacity 1s;
        opacity: 1 !important;
      }
    }
  }

  .Section.Nine {
    width: 100vw;
    height: calc(var(--heightRatio) * 100vw);
    overflow: hidden;
    display: flex;
    background-color: #e3e3e3;

    .LeftHalf {
      flex: 1;
      overflow: hidden;

      .JanáčekTheatre1 {
        width: 50vw;
        height: calc(var(--heightRatio) * 100vw);
        background-image: url("../Assets/Images/NDB/Section9/JanáčekTheatre1.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .RightHalf {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        width: 25vw;
      }
    }
  }

  .Section.Ten {
    width: 100vw;
    height: calc(var(--heightRatio) * 100vw);
    overflow: hidden;
    display: flex;
    background-color: #e3e3e3;

    .LeftHalf {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        width: 25vw;
      }
    }

    .RightHalf {
      flex: 1;
      overflow: hidden;

      .JanáčekTheatre2 {
        width: 50vw;
        height: calc(var(--heightRatio) * 100vw);
        background-image: url("../Assets/Images/NDB/Section10/JanáčekTheatre2.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }

  .Section.Eleven {
    background-color: #e3e3e3;
    width: 100vw;
    height: calc(var(--heightRatio) * 100vw);
    display: flex;
    justify-content: center;
    align-items: center;

    .YellowEllipse {
      width: 60vw;
      height: 60vw;
      position: absolute;
      left: 20vw;
      background-image: url("../Assets/Images/Chapter/Slider/YellowEllipse.png");
      background-size: contain;
      background-repeat: no-repeat;
      transition: all 1.5s;
      top: calc((var(--heightRatio) - 0.6) / 2 * 100vw);
    }

    .Paragraph.Five {
      position: relative;

      p {
        text-align: center;
        line-height: 2vw;
      }
    }
  }

  .Section.Twelve {
    width: 100vw;
    height: calc(var(--heightRatio) * 70vw);
    display: flex;

    .NDBVideoContainer {
      flex: 1;
      display: flex;
      background-color: #000000;
      z-index: -100;
      height: calc(var(--heightRatio) * 50vw);

      .NDBVideoMask {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: absolute;
        z-index: 50;
        transition: all 1s;
        width: calc(100 / 3 * 1vw);
        height: calc(var(--heightRatio) * 50vw);

        p {
          color: #ffffff;
          font-family: acumin-pro, sans-serif;
          font-weight: 600;
          font-size: 2vw;
          line-height: 2.5vw;
          text-align: center;
        }

        .VideoMaskBtn {
          width: 3.5vw;
          height: 3.5vw;
        }
      }

      #NDBv2 {
        left: calc(100 / 3 * 1vw);
      }

      #NDBv3 {
        left: calc(100 / 3 * 2vw);
      }

      .NDBVideo {
        flex: 1;
        width: calc(100 / 3 * 1vw);
        height: calc(var(--heightRatio) * 50vw);
        // position: absolute;
        transition: all 0.5s;
      }
    }

    .MapRedirect {
      background-color: #000000;
      position: absolute;
      top: calc(var(--heightRatio) * 50vw);
      height: calc(var(--heightRatio) * 20vw);
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      p {
        color: #ffffff;
        font-family: adorn-coronet, sans-serif;
        font-size: 3.5vw;
        line-height: 1.75vw;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .NDB.ZH-HANT {
    .Section.One {
      .ChapterName {
        top: 30vh !important;

        p {
          font-size: 14vw !important;
          line-height: 14vw !important;
        }
      }
    }

    .Section.Three {
      .TimelineEvent {
        h1 {
          font-size: 8vw !important;
        }
      }

      .TimelineEvent.One {
        top: 48vh !important;
      }

      .TimelineEvent.Two {
        top: 70vh !important;
      }

      .TimelineEvent.Three {
        top: 91vh !important;
      }

      .TimelineEvent.Four {
        top: 112vh !important;
      }

      .TimelineEvent.Five {
        top: 133vh !important;
      }

      .TimelineEvent.Six {
        top: 154vh !important;
      }

      .TimelineEvent.Seven {
        top: 175vh !important;
      }
    }

    .Section.ThreePointTwo {
      .TheatrenaVeveříTitle.ZH {
        p {
          font-size: 7vw !important;
        }
      }

      .Quote.One {
        font-size: 5vw !important;
        line-height: 7vw !important;
      }
    }

    .Section.Eight {
      .TheatreTitle.ZH {
        p {
          font-size: 15vw !important;
          line-height: 15vw !important;
          text-align: center !important;
          margin: 0;
        }
      }
    }

    .Section.Eleven {
      .Paragraph.Five {
        width: initial !important;
      }
    }
  }

  .NDB {
    overflow-x: hidden !important;
    // background-position: -100vw !important;
    background-size: 139.9vh 100vh !important;
    background-attachment: scroll !important;

    .Section.One {
      .ChapterName {
        width: initial !important;
        height: initial !important;
        right: initial !important;
        left: 0 !important;
        top: 25vh !important;

        p {
          font-size: 21vw !important;
          line-height: 21vw !important;
          text-align: center;
          width: 100vw !important;
        }
      }

      .Paragraph.One {
        width: 85vw !important;
        right: 7.5vw !important;
        top: 70vh !important;
      }
    }

    .Section.Two {
      .ScrollMagicWrapper {
        .RedutaTheatrePhoto {
          background-position: -100vw;
        }

        .TheatreTitle {
          p {
            font-size: 15vw !important;
          }
        }
      }
    }

    .Section.Three {
      height: 220vh !important;

      .Ellipse.Green {
        background-image: url("../Assets/Images/Chapter/Section2/GreenEllipse.png");
        width: 40vw;
        height: 40vw;
        top: calc(var(--heightRatio) * 140vw);
        right: -6vw;
      }

      .Paragraph.Two {
        width: 85vw !important;
        left: 7.5vw !important;
        top: 10vh !important;
      }

      .BGDots.RightDots {
        background-image: initial !important;
      }

      .Timeline {
        width: 10vw !important;
        height: 160vh !important;
        left: 7.5vw !important;
        top: 50vh !important;
      }

      .TimelineEvent {
        left: 25vw !important;

        h1 {
          font-size: 14vw !important;
          line-height: 14vw !important;
        }

        p {
          width: 67.5vw !important;
          font-size: 3.75vw !important;
          line-height: 5vw !important;
        }
      }

      .TimelineEvent.One {
        top: 49vh !important;
      }

      .TimelineEvent.Two {
        top: 71vh !important;
      }

      .TimelineEvent.Three {
        top: 92vh !important;
      }

      .TimelineEvent.Four {
        top: 113vh !important;
      }

      .TimelineEvent.Five {
        top: 134vh !important;
      }

      .TimelineEvent.Six {
        top: 155vh !important;
      }

      .TimelineEvent.Seven {
        top: 176vh !important;
      }
    }

    .Section.ThreePointTwo {
      .TheatrenaVeveříTitle {
        width: 80vw !important;
        height: 14vw !important;
        margin-bottom: 2vh !important;
      }

      .TheatrenaVeveříTitle.ZH {
        background-image: initial;
        width: auto;

        p {
          color: #ffffff;
          font-family: yu-mincho-pr6, sans-serif !important;
          font-weight: 600;
          font-size: 2vw;
          margin: 0;
          width: auto;
        }
      }

      h1 {
        font-size: 8vw !important;
        line-height: 9vw !important;
      }

      p {
        font-size: 4vw !important;
        line-height: 6.5vw !important;
        width: 85vw !important;
        margin-bottom: 2vh !important;
      }

      .Quote.One {
        font-size: 7vw !important;
        line-height: 8vw !important;
      }
    }

    .Section.Four {
      .ScrollMagicWrapper {
        .MahenTheatrePhoto {
          background-position: -132vw;
        }

        .TheatreTitle {
          p {
            font-size: 15vw !important;
          }
        }
      }
    }

    .Section.Five {
      .Ellipse.Green {
        width: 100vw !important;
        height: 100vw !important;
        top: 335vh !important;
        right: -30vw !important;
      }

      .Ellipse.Blue {
        width: 140vw !important;
        height: 140vw !important;
        top: 405vh !important;
        left: -30vw !important;
      }

      .Paragraph.Four {
        width: 85vw !important;
      }
    }

    .Section.Six {
      width: 100vw !important;
      height: 60vh !important;

      .StaircaseBG {
        height: 60vh !important;
      }

      .Staircase {
        width: 100vw !important;
        height: initial !important;
        flex-direction: column;

        .Images.StaircasePhoto {
          width: 100vw;
          height: 62.5vw !important;
        }

        .StaircasePhotoInfo {
          width: 100vw !important;
          height: 30vh !important;

          p {
            font-size: 3.75vw !important;
            line-height: 5vw !important;
            width: 85vw !important;
          }
        }
      }
    }

    .Section.Seven {
      height: 80vh !important;

      .StageBG {
        height: 80vh !important;
      }

      .Stage {
        width: 100vw !important;
        height: initial !important;
        flex-direction: column;

        .Images.StagePhoto {
          height: 62.5vw !important;
        }

        .StagePhotoInfo {
          width: 100vw !important;
          height: 51vh !important;

          p {
            font-size: 3.75vw !important;
            line-height: 5vw !important;
            width: 85vw !important;
          }
        }
      }
    }

    .Section.Eight {
      height: 200vh;

      .JanáčekTheatrePhoto {
        background-position: -130vw;
      }

      .TheatreTitleContainer {
        .TheatreTitle {
          width: 80vw !important;
          height: 16vw !important;
        }
      }
    }

    .Section.Nine {
      height: 100vh !important;
      flex-direction: column;

      .LeftHalf {
        flex: 1;
        overflow: hidden;

        .JanáčekTheatre1 {
          width: 100vw !important;
          height: 125vw !important;
        }
      }

      .RightHalf {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          width: 85vw !important;
          font-size: 3.75vw !important;
          line-height: 5vw !important;
        }
      }
    }

    .Section.Ten {
      height: 100vh !important;
      flex-direction: column;

      .LeftHalf {
        p {
          width: 85vw !important;
          font-size: 3.75vw !important;
          line-height: 5vw !important;
        }
      }

      .RightHalf {
        .JanáčekTheatre2 {
          width: 100vw !important;
          height: 125vw !important;
        }
      }
    }

    .Section.Eleven {
      height: 100vh !important;

      .YellowEllipse {
        width: 225vw !important;
        height: 225vw !important;
        left: -62.5vw !important;
      }

      .Paragraph.Five {
        p {
          text-align: justify !important;
          width: 85vw !important;
          font-size: 3.75vw !important;
          line-height: 5vw !important;
        }
      }
    }

    .Section.Twelve {
      height: calc(56.25 * 4vw) !important;
      flex-direction: column;

      .NDBVideoContainer {
        height: 56.25vw !important;

        .NDBVideoMask {
          width: 100vw !important;
          height: 56.25vw !important;

          p {
            font-size: 6vw !important;
            line-height: 7.5vw !important;
          }

          .VideoMaskBtn {
            width: 3.5vw;
            height: 3.5vw;
          }
        }

        #NDBv2 {
          left: calc(100 / 3 * 1vw);
        }

        #NDBv3 {
          left: calc(100 / 3 * 2vw);
        }

        .NDBVideo {
          width: 100vw !important;
          height: 56.25vw !important;
          padding-top: 15vw;
        }
      }

      .MapRedirect {
        position: initial !important;
        height: 25vw !important;

        p {
          font-size: 7vw !important;
          line-height: 3.5vw !important;
        }
      }
    }
  }
}
