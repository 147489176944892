@media only screen and (max-width: 1366px) {
	.Tooltip {
		border-radius: 1vw;

		h1,
		p {
			margin: calc(1366 * 0.008px) calc(1366 * 0.015px) !important;
			line-height: calc(1366 * 0.01px) !important;
		}

		h1 {
			font-size: calc(1366 * 0.015px) !important;
			margin-top: calc(1366 * 0.015px) !important;
		}

		p {
			font-size: calc(1366 * 0.008px) !important;
			margin-bottom: calc(1366 * 0.015px) !important;
		}
	}

	.Tooltip.BrnoTheatre .Arrow {
		width: calc(1366 * 0.015px) !important;
		height: calc(1366 * 0.015px) !important;
	}

	.Tooltip.Smetana h1,
	.Tooltip.Dvorak h1,
	.Tooltip.Janacek h1,
	.Tooltip.Martinu h1 {
		margin-bottom: calc(1366 * 0.015px) !important;
	}
}

.Tooltip {
	position: absolute;
	z-index: -100;
	opacity: 0;
	transition: all 0.5s;
	background-color: rgba(0, 0, 0, 0.75);
	border-radius: 1vw;
	white-space: pre-line;

	h1,
	p {
		font-family: acumin-pro, sans-serif;
		color: #ffffff;
		margin: 0.8vw 1.5vw;
		line-height: 1vw;
		text-align: initial;
	}

	h1 {
		font-weight: 400;
		font-size: 1.5vw;
		margin-top: 1.5vw;
		width: fit-content;
		display: flex;
		flex-direction: row;
		align-items: baseline;

		h1 {
			width: fit-content;
			margin: 0;
			font-size: 1vw;
		}
	}

	p {
		font-size: 0.8vw;
		margin-bottom: 1.5vw;
		// width: 21vw;
	}
}

.Tooltip.JanacekHouse {
	bottom: 41%;
	right: 40%;
}

.SelectedBuilding.JanacekHouse .Tooltip.JanacekHouse {
	bottom: 44%;
	opacity: 1;
	z-index: 100;
}

.Tooltip.JanacekTheatre {
	bottom: 33%;
	right: 36.5%;
}

.SelectedBuilding.JanacekTheatre .Tooltip.JanacekTheatre {
	bottom: 36%;
	opacity: 1;
	z-index: 100;
}

.Tooltip.RedutaTheatre {
	bottom: 18%;
	right: 42%;
}

.SelectedBuilding.RedutaTheatre .Tooltip.RedutaTheatre {
	bottom: 21%;
	opacity: 1;
	z-index: 100;
}

.Tooltip.OrganistHouse {
	bottom: 81%;
	left: 6%;
}

.SelectedBuilding.OrganistHouse .Tooltip.OrganistHouse {
	bottom: 84%;
	opacity: 1;
	z-index: 100;
}

.Tooltip.SychrovCastle {
	bottom: 80%;
	right: 16%;
}

.SelectedBuilding.SychrovCastle .Tooltip.SychrovCastle {
	bottom: 83%;
	opacity: 1;
	z-index: 100;
}

.Tooltip.MoravianTheatre {
	bottom: 53.5%;
	right: 7%;
}

.SelectedBuilding.MoravianTheatre .Tooltip.MoravianTheatre {
	bottom: 56.5%;
	opacity: 1;
	z-index: 100;
}

.Tooltip.MonumentOfBystrouška {
	bottom: 56%;
	right: 2%;
}

.SelectedBuilding.MonumentOfBystrouška .Tooltip.MonumentOfBystrouška {
	bottom: 59%;
	opacity: 1;
	z-index: 100;
}

.Tooltip.LitomyšlCastle {
	bottom: 59%;
	right: 18%;
}

.SelectedBuilding.LitomyšlCastle .Tooltip.LitomyšlCastle {
	bottom: 62%;
	opacity: 1;
	z-index: 100;
}

.Tooltip.StJamesChurch {
	bottom: 56%;
	right: 25%;
}

.SelectedBuilding.StJamesChurch .Tooltip.StJamesChurch {
	bottom: 59%;
	opacity: 1;
	z-index: 100;
}

.Tooltip.CharlesBridge {
	bottom: 63%;
	left: 20.5%;
}

.SelectedBuilding.CharlesBridge .Tooltip.CharlesBridge {
	bottom: 66%;
	opacity: 1;
	z-index: 100;
}

.Tooltip.Rudolfinum {
	bottom: 76%;
	left: 31%;
}

.SelectedBuilding.Rudolfinum .Tooltip.Rudolfinum {
	bottom: 79%;
	opacity: 1;
	z-index: 100;
}

.Tooltip.EstatesTheatre {
	bottom: 72%;
	right: 33%;
}

.SelectedBuilding.EstatesTheatre .Tooltip.EstatesTheatre {
	bottom: 75%;
	opacity: 1;
	z-index: 100;
}

.Tooltip.NationalTheatre {
	bottom: 60%;
	right: 39%;
}

.SelectedBuilding.NationalTheatre .NationalTheatre {
	bottom: 63%;
	opacity: 1;
	z-index: 100;
}

.Tooltip.Vyšehrad {
	bottom: 49%;
	left: 28%;
}

.SelectedBuilding.Vyšehrad .Tooltip.Vyšehrad {
	bottom: 52%;
	opacity: 1;
	z-index: 100;
}

.Tooltip.DvorakMemorial {
	bottom: 48%;
	left: 26%;
}

.SelectedBuilding.DvorakMemorial .Tooltip.DvorakMemorial {
	bottom: 51%;
	opacity: 1;
	z-index: 100;
}

.Tooltip.AugustinianHouse {
	bottom: 43%;
	right: 1%;
}

.SelectedBuilding.AugustinianHouse .Tooltip.AugustinianHouse {
	bottom: 46%;
	opacity: 1;
	z-index: 100;
}

.Tooltip.BrnoTheatre {
	bottom: 35%;
	right: 32%;

	.Arrow {
		background-image: url("../Assets/Images/Map/Arrow.png");
		background-repeat: no-repeat;
		background-size: contain;
		width: 1.5vw;
		height: 1.5vw;
		position: absolute;
		right: 1vw;
		bottom: 0.5vw;
		cursor: pointer;
	}
}

.SelectedBuilding.BrnoTheatre .Tooltip.BrnoTheatre {
	bottom: 38%;
	opacity: 1;
	z-index: 100;
}

.Tooltip.Dvorak {
	top: 16%;
	left: 2%;

	h1 {
		margin-bottom: 1.5vw;
	}
}

.SelectedComposer.Dvorak {
	.Tooltip.Dvorak {
		top: 13%;
		opacity: 1;
		z-index: 100;
	}
}

.Tooltip.Janacek {
	bottom: 31%;
	right: 5%;

	h1 {
		margin-bottom: 1.5vw;
	}
}

.SelectedComposer.Janacek {
	.Tooltip.Janacek {
		bottom: 34%;
		opacity: 1;
		z-index: 100;
	}
}

.Tooltip.Martinu {
	top: 15%;
	right: 13%;

	h1 {
		margin-bottom: 1.5vw;
	}
}

.SelectedComposer.Martinu {
	.Tooltip.Martinu {
		top: 12%;
		opacity: 1;
		z-index: 100;
	}
}

.Tooltip.Smetana {
	bottom: 19%;
	left: 7%;

	h1 {
		margin-bottom: 1.5vw;
	}
}

.SelectedComposer.Smetana {
	.Tooltip.Smetana {
		bottom: 22%;
		opacity: 1;
		z-index: 100;
	}
}
