// @import "./Chapter.scss";

.Intro {
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
	white-space: pre-line;
	z-index: 0;
	background-color: #626767;

	.IntroBG {
		height: calc(var(--heightRatio) * 100vw);
		width: 100vw;
		background-image: url("../Assets/Images/Intro/Background.jpeg");
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: absolute;
		z-index: -10;
	}

	.BlackMask {
		width: 100vw;
		height: calc(var(--heightRatio) * 100vw);
		background-color: rgba(0, 0, 0, 0.5);
		position: absolute;
		z-index: -10;
	}

	h1 {
		font-family: adorn-coronet, sans-serif;
		font-size: 6vw;
		font-weight: 100;
		line-height: 8vw;
		margin: 0;
		color: #ffffff;
	}

	p {
		line-height: 1.75vw;
		font-size: 1.25vw;
		padding-bottom: 15%;
		width: 40vw;
		text-align: justify;
		text-justify: inter-character;
		color: #ffffff;
	}

	.Ellipse.Green {
		background-image: url("../Assets/Images/Chapter/Section2/GreenEllipse.png");
		width: 35vw;
		height: 35vw;
		bottom: -25%;
		left: 0%;
		transform: rotate(-15deg);
		animation: LeftEllipseFloating 1.5s forwards;
	}

	.Ellipse.Blue {
		background-image: url("../Assets/Images/Chapter/Section1/BlueEllipse.png");
		width: 40vw;
		height: 40vw;
		right: -2.5%;
		top: 0%;
		animation: RightEllipseFloating 1.5s forwards;
	}

	.DotsRect {
		z-index: -10;
	}
}

@keyframes LeftEllipseFloating {
	0% {
		bottom: 40%;
	}
	100% {
		bottom: -25%;
	}
}

@keyframes RightEllipseFloating {
	0% {
		top: 80%;
	}
	100% {
		top: 0%;
	}
}
