// @import "./Chapter.scss";

.ChapterJanacek.ZH-HANT {
  .Quote.One p {
    font-size: 1.5vw !important;
    line-height: 1.75vw !important;
    font-weight: 100 !important;
  }

  .Section.Eight .Paragraph.Four {
    top: 15% !important;

    .Title {
      margin-bottom: 3vw !important;
    }

    h1 {
      font-size: 3vw !important;
    }

    .Quote.One {
      margin-top: 3vw !important;
    }
  }

  .Spotify {
    top: calc(var(--heightRatio) * 38vw) !important;
  }

  .Slide.One {
    h1 {
      position: absolute;
      top: 32%;
    }

    p {
      position: absolute;
      top: 48%;
    }

    .MusicNote {
      position: absolute;
      top: 62%;
    }
  }

  .Slide.Two {
    h1 {
      position: absolute;
      top: 23%;
    }

    p {
      position: absolute;
      top: 39%;
      width: 32vw;
    }

    .MusicNote {
      position: absolute;
      top: 70.2%;
    }
  }

  .Slide.Three {
    h1 {
      position: absolute;
      top: 24.5%;
    }

    p {
      position: absolute;
      top: 41.5%;
    }

    .MusicNote {
      position: absolute;
      top: 68.5%;
    }
  }

  .Slide.Four {
    h1 {
      position: absolute;
      top: 28%;
    }

    p {
      position: absolute;
      top: 44%;
    }

    .MusicNote {
      position: absolute;
      top: 65.2%;
    }
  }
}

.ChapterJanacek {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url("../Assets/Images/Janacek/Janecek.jpg");
  background-size: cover;
  width: 100vw;
  overflow-x: hidden;

  .ChapterCaption {
    bottom: calc(var(--heightRatio) * 4vw);

    p {
      padding: 0.35vw;
      border-radius: 0.6vw;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  .Section.One {
    .ChapterName {
      background-image: url("../Assets/Images/Janacek/Section1/ComposerName.png");
    }
  }

  .Section.Two {
    .clipContent {
      .Paragraph {
        position: relative;

        h1 {
          margin-bottom: 2vw;
          text-align: center;
        }

        p {
          width: 32vw;
        }
      }
    }
  }

  .Section.Four,
  .Section.Six,
  .Section.Eight {
    .BGDots.LeftDots {
      width: 20vw;
      height: 42vw;
      left: -1.7%;
      bottom: 0%;
      transform: rotateY(180deg);
    }

    .BGDots.RightDots {
      width: 18vw;
      height: 38vw;
      right: -1vw;
      top: 0%;
    }
  }

  .Section.Four {
    .Images.Folksong {
      left: 0%;
      top: 0%;
      width: 50vw;
      height: calc(var(--heightRatio) * 100vw);
      overflow: hidden;

      img {
        width: auto;
        max-width: none;
      }
    }

    .Caption.Folksong {
      position: absolute;
      right: 52vw;
      bottom: calc(var(--heightRatio) * 12vw);

      p {
        color: #ffffff;
      }
    }

    .Paragraph.Two {
      left: 60%;
      top: 10%;

      p {
        width: 31vw;
      }
    }
  }

  .Section.Five {
    height: calc(var(--heightRatio) * 100vw);

    .ProgressBar {
      width: 12vw;
    }

    .Slides.One,
    .Slides.Two2One {
      .Slide.One {
        opacity: 1;
      }

      .SliderBtn.PrevSlide {
        opacity: 0;
        pointer-events: none;
      }

      .Slide.Zero {
        .ProgressBar {
          left: 20vw;
        }
      }
    }

    .Slides.One2Two,
    .Slides.Three2Two {
      .Slide.Zero {
        .ProgressBar {
          left: 32vw;
        }
      }
    }

    .Slides.Two2Three,
    .Slides.Four2Three {
      .Slide.Zero {
        .ProgressBar {
          left: 44vw;
        }
      }
    }

    .Slides.Three2Four,
    .Slides.Five2Four {
      .Slide.Zero {
        .ProgressBar {
          left: 56vw;
        }
      }
    }

    .Slides.Four2Five {
      .SliderBtn.NextSlide {
        opacity: 0;
        pointer-events: none;
      }

      .Slide.Zero {
        .ProgressBar {
          left: 68vw;
        }

        .YellowEllipse {
          opacity: 0;
        }
      }
    }

    .Slide.One {
      .ScrollGIF {
        background-image: url("../Assets/Images/Map/Composer/Janacek.gif");
      }
    }

    .Slide.Three {
      p {
        font-family: acumin-pro, sans-serif;
      }
    }

    .Slide.Five {
      display: flex;
      justify-content: center;
      align-items: center;

      .Rusalka {
        width: 100vw;
        height: calc(var(--heightRatio) * 100vw);
        position: absolute;
        top: -1%;
      }
    }
  }

  .Section.Six {
    .Images.Folksong {
      left: 0;
      top: 0;
      width: 50vw;
      height: calc(var(--heightRatio) * 100vw);
      overflow: hidden;

      img {
        width: auto;
        max-width: none;
      }
    }

    .Caption.Folksong {
      position: absolute;
      bottom: calc(var(--heightRatio) * 12vw);
      right: 52vw;
      z-index: 10;

      p {
        color: #ffffff;
      }
    }

    .Paragraph.Three {
      left: 60vw;
      top: 10%;

      p {
        width: 32vw;
      }

      .Quote.One {
        p {
          font-size: 2.5vw;
          line-height: 2.5vw;
          width: 40vw;
          margin-top: 2.75vw;
        }
      }
    }

    .Paragraph.ThreePointFive {
      // left: 60vw;
      // top: calc((var(--heightRatio) * 35vw) + 20vw);

      p {
        width: 31vw;
      }
    }
  }

  .Section.Seven {
    background-color: transparent;
  }

  .Section.Eight {
    .Paragraph.Four {
      left: 15%;
      top: 2%;

      h1 {
        font-size: 4vw;
      }

      p {
        width: 70vw;
      }
    }

    .Quote.One {
      margin-top: 2.5vw;

      p {
        margin: 0;
        font-size: 2.5vw;
        line-height: 2.5vw;
      }
    }

    .Spotify {
      position: absolute;
      left: 55vw;
      top: calc(var(--heightRatio) * 40vw);
    }

    .Paragraph.Five {
      left: 15%;
      top: calc(var(--heightRatio) * 64vw);
      width: 70vw;
    }
  }

  .Legacy {
    .Ellipse.Left {
      bottom: calc(var(--heightRatio) * -465vw);
    }

    .FooterSwitch.Hover {
      .Ellipse.Right {
        width: 5vw;
        height: 5vw;
        right: 0vw;
        top: calc(var(--heightRatio) * 447.5vw);
      }
    }

    .Signature {
      width: 12.5vw;
      margin-top: 1.5vw;
      z-index: 50;
    }
  }
}

@media only screen and (max-width: 450px) {
  .ChapterJanacek.ZH-HANT {
    .Section.Six {
      .Paragraph.Three {
        .Quote.One {
          p {
            margin-top: 4vh !important;
            font-size: 5vw !important;
            line-height: 7vw !important;
          }
        }

        .PThreePointTwo {
          margin-top: 20vh !important;
        }
      }
    }

    .Section.Eight {
      height: 110vh !important;

      .Paragraph.Four {
        top: 5vh !important;

        .Title {
          h1 {
            font-size: 7vw !important;
          }
        }

        .Quote.One {
          p {
            margin-top: 1vh !important;
            font-size: 5vw !important;
            line-height: 7vw !important;
          }
        }
      }

      .Spotify {
        top: 75vh !important;
      }
    }
  }

  .ChapterJanacek {
    // background-position: -100vw !important;
    background-size: 127.1vh 100vh !important;
    background-attachment: scroll !important;

    .ChapterCaption {
      bottom: 2vh !important;

      p {
        padding: 1vw !important;
        border-radius: 1.2vw !important;
      }
    }

    .Section.One {
      .ChapterName {
        background-image: url("../Assets/Images/Janacek/Section1/ComposerName_m.png") !important;
      }
    }

    .Section.Two {
      p {
        width: 85vw !important;
      }
    }

    .Section.Four {
      height: 110vh !important;

      .Images.Folksong {
        width: 100vw !important;
        height: 119vw !important;
      }

      .Caption.Folksong {
        bottom: initial !important;
        right: 5vw !important;
        top: 100vw !important;
        width: 90vw !important;
      }

      .Paragraph.Two {
        left: 7.5vw !important;
        top: 60vh !important;

        p {
          width: 85vw !important;
        }
      }

      .BGDots.RightDots {
        width: 36vw !important;
        height: 76vw !important;
        right: -1vw;
        top: 40vh !important;
      }
    }

    .Section.Six {
      height: 125vh !important;

      .Images.Folksong {
        width: 100vw !important;
        height: 119vw !important;
      }

      .Caption.Folksong {
        bottom: initial !important;
        right: 5vw !important;
        top: 100vw !important;
      }

      .Paragraph.Three {
        left: 7.5vw !important;
        top: 60vh !important;

        p {
          width: 85vw !important;
        }

        .Quote.One {
          p {
            font-size: 7.5vw !important;
            line-height: 7.5vw !important;
            width: 40vw;
            margin-top: 2.75vw;
          }
        }

        .PThreePointTwo {
          margin-top: 16vh !important;
        }
      }
    }

    .Section.Eight {
      height: 160vh !important;

      .Paragraph.Four {
        left: 7.5vw !important;
        top: 5vh !important;

        h1 {
          font-size: 10vw !important;
        }

        p {
          width: 85vw !important;
        }

        .PFourPointTwo {
          margin-top: 17vh !important;
        }
      }

      .Quote.One {
        p {
          font-size: 7.5vw !important;
          line-height: 7.5vw !important;
        }
      }

      .Spotify {
        left: 7.5vw !important;
        top: 75vh !important;
      }

      .BGDots.RightDots {
        width: 36vw !important;
        height: 76vw !important;
      }

      .BGDots.LeftDots {
        width: 40vw !important;
        height: 82vw !important;
      }
    }

    .Legacy {
      .Signature {
        width: 25vw !important;
        margin-top: 3vh !important;
      }

      .Ellipse.Left {
        bottom: -498vh !important;
      }
    }
  }
}
