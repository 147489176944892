@media only screen and (max-width: 450px) {
	.MenuToggler {
		right: 7.5vw !important;
		top: calc(var(--heightRatio) * 10vw) !important;
		height: 10vw !important;
		width: 10vw !important;

		.Line {
			width: 10vw !important;
			height: 1vw !important;
			border-radius: 8vw !important;
		}

		.Line::before,
		.Line::after {
			width: 10vw !important;
			height: 1vw !important;
			border-radius: 8vw !important;
		}

		.Line::before {
			transform: translateY(calc(-1 * var(--heightRatio) * 5vw)) !important;
		}

		.Line::after {
			transform: translateY(calc(var(--heightRatio) * 5vw)) !important;
			width: 5vw !important;
			margin-left: 5vw !important;
		}
	}

	.MenuToggler.Opened {
		.Line {
			transform: translateX(-5vw) !important;
		}

		.Line::before {
			transform: rotateZ(45deg) translate(5vw, -5vw) !important;
		}

		.Line::after {
			transform: rotateZ(-45deg) translate(5vw, 5vw) !important;
			margin-left: 0vw !important;
			width: 10vw !important;
		}
	}
}

.MenuToggler {
	position: fixed;
	right: 2.5vw;
	top: calc(var(--heightRatio) * 2.5vw);
	height: 4vw;
	width: 4vw;
	z-index: 1500;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;

	.Line {
		width: 2.5vw;
		height: 0.25vw;
		background-color: #192d42;
		border-radius: 2vw;
		transition: all 0.5s;
	}

	.Line::before,
	.Line::after {
		width: 2.5vw;
		height: 0.25vw;
		background-color: #192d42;
		border-radius: 2vw;
		content: "";
		position: absolute;
		transition: all 0.5s;
	}

	.Line::before {
		transform: translateY(calc(-1 * var(--heightRatio) * 1.25vw));
	}

	.Line::after {
		transform: translateY(calc(var(--heightRatio) * 1.25vw));
		width: 1.25vw;
		margin-left: 1.25vw;
	}
}

.MenuToggler.Opened {
	.Line {
		background-color: transparent;
		transform: translateX(-1vw);
	}

	.Line::before {
		transform: rotateZ(45deg) translate(1vw, -1vw);
	}

	.Line::after {
		transform: rotateZ(-45deg) translate(1vw, 1vw);
		margin-left: 0vw;
		width: 2.5vw;
	}
}
