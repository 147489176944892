// @import "./Chapter.scss";

.ChapterMartinu.ZH-HANT {
	.Section.FivePointOne {
		.RightHalf {
			.Paragraph.Three {
				width: 35vw !important;
			}

			.Paragraph.Four {
				width: 35vw !important;
			}

			.Paragraph.Six {
				width: 35vw !important;
			}
		}

		.Spotify {
			top: calc(var(--heightRatio) * 62vw) !important;
		}

		.Title {
			.MusicNotes {
				margin-left: 0 !important;
			}

			.Špalíček {
				background-image: initial !important;
				width: 22vw;

				h1 {
					font-size: 3vw;
				}
			}
		}
	}

	.Section.FivePointTwo .Quote {
		top: calc(var(--heightRatio) * 29vw) !important;

		p {
			font-size: 5vw !important;
			line-height: 6vw !important;
			font-weight: 400 !important;
		}
	}

	.Slide.One {
		h1 {
			position: absolute;
			top: 23.5%;
		}

		p {
			position: absolute;
			top: 39.5%;
			width: 32vw !important;
		}

		.MusicNote {
			position: absolute;
			top: 70.5%;
		}
	}

	.Slide.Two {
		h1 {
			position: absolute;
			top: 25%;
		}

		p {
			position: absolute;
			top: 41%;
		}

		.MusicNote {
			position: absolute;
			top: 68.5%;
		}
	}

	.Slide.Three {
		h1 {
			position: absolute;
			top: 23%;
		}

		p {
			position: absolute;
			top: 39%;
		}

		.MusicNote {
			position: absolute;
			top: 70.2%;
		}
	}
}

.ChapterMartinu {
	background-color: #000000;
	overflow-x: hidden;

	.Images.ChapterBG {
		position: fixed;
		left: 35vw;
		top: calc(var(--heightRatio) * 7.5vw);
		width: 30vw;
		height: calc(var(--heightRatio) * 73vw);
	}

	.ChapterCaption {
		right: 0%;
		width: 100vw;
		bottom: calc(var(--heightRatio) * 5vw);

		p {
			color: #ffffff;
			text-align: center;
		}
	}

	.Section.One {
		.ChapterName {
			background-image: url("../Assets/Images/Martinu/Section1/ComposerName.png");
		}
	}

	.Section.Two {
		.clipContent {
			.ImgContainer.Church {
				width: 24vw;
				height: 31vw;
				left: 55%;
				top: 18%;
			}

			.Paragraph.One {
				left: 20%;
				top: 25%;
				width: 31vw;
			}

			.Caption.Church {
				// text-align: center
				padding-left: 2vw;
			}
		}
	}

	.Section.Three {
		background-color: transparent;
	}

	.Section.Four {
		background-color: rgba(35, 39, 39, 0.5);
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
		justify-content: center;

		h1 {
			color: #ffffff;
			font-size: 1.75vw;
			font-weight: 600;
			opacity: 0;
		}

		p {
			color: #ffffff;
			line-height: 1.75vw;
			font-size: 1.25vw;
			opacity: 0;
			text-align: justify;
			text-justify: inter-character;
			width: 30vw;
		}
	}

	.Section.Four.Active {
		h1,
		p {
			animation: opacityChanging 3s forwards;
		}
	}

	.Section.Five {
		.BGDots.RightDots {
			width: 20vw;
			height: 40vw;
			right: -1vw;
			top: 0;
		}

		.Images.InParis {
			width: 50vw;
			height: calc(var(--heightRatio) * 100vw);
			top: 0;
			left: 0;
			overflow: hidden;

			img {
				width: auto;
				max-width: none;
			}
		}

		.Caption.InParis {
			position: absolute;
			right: 52vw;
			bottom: calc(var(--heightRatio) * 12vw);

			p {
				color: #ffffff;
			}
		}

		.Paragraph.Two {
			left: 60vw;
			top: 10%;
			width: 31vw;
		}
	}

	.Section.FivePointOne {
		height: calc(var(--heightRatio) * 100vw);

		.Title {
			position: absolute;
			left: 15vw;
			top: calc(var(--heightRatio) * 7.5vw);

			.Špalíček {
				width: 11.5vw;
				height: 4.5vw;
				background-image: url("../Assets/Images/Martinu/Section5/Spalicek.png");
				background-repeat: no-repeat;
				background-size: contain;
			}
		}

		.ImgContainer.MartinuIllustration {
			width: 27vw;
			height: 36vw;
			top: calc(var(--heightRatio) * 20vw);
			left: 15vw;

			.Images.MartinuIllustration {
				width: 100%;
				height: 36vw;
			}

			.Caption.MartinuIllustration {
				width: 27vw;
			}
		}

		.RightHalf {
			position: absolute;
			left: 52.5vw;
			top: calc(var(--heightRatio) * 7.5vw);

			.Paragraph.Three {
				width: 37vw;
				position: relative;
				margin-bottom: 2vw;
			}

			.Paragraph.Four {
				width: 37vw;
				position: relative;
				margin-bottom: 2vw;
			}

			.Paragraph.Six {
				width: 37vw;
				position: relative;
				margin-bottom: 2vw;
			}
		}

		.Spotify {
			position: absolute;
			left: 52.5vw;
			top: calc(var(--heightRatio) * 75vw);
		}
	}

	.Section.FivePointTwo {
		.QuoteMark {
			width: 7.5vw;
			height: 7.5vw;
			top: calc(var(--heightRatio) * 39vw);
			left: 13vw;
		}

		.QuoteMark.Close {
			top: calc(var(--heightRatio) * 45vw);
			left: 81vw;
		}

		.Quote {
			top: calc(var(--heightRatio) * 25vw);
			left: 24vw;

			p {
				font-size: 7vw;
				line-height: 7vw;
			}
		}
	}

	.Section.FivePointThree {
		.Images.ArriveUSA {
			width: 50vw;
			height: calc(var(--heightRatio) * 100vw);
			top: 0;
			left: 0;
			overflow: hidden;

			img {
				width: auto;
				max-width: none;
			}
		}

		.Caption.ArriveUSA {
			position: absolute;
			right: 52vw;
			bottom: calc(var(--heightRatio) * 12vw);
			// width: 45vw;

			p {
				color: #ffffff;
			}
		}

		.Paragraph.Seven {
			left: 60vw;
			top: 10%;
			width: 31vw;
		}
	}

	.Section.Six {
		height: calc(var(--heightRatio) * 100vw);

		.ProgressBar {
			width: 15vw;
		}

		.Slide.Four {
			z-index: -50;
		}

		.Slides.One,
		.Slides.Two2One {
			.Slide.One {
				opacity: 1;
			}

			.SliderBtn.PrevSlide {
				opacity: 0;
				pointer-events: none;
			}

			.Slide.Zero {
				.ProgressBar {
					left: 20vw;
				}
			}
		}

		.Slides.One2Two,
		.Slides.Three2Two {
			.Slide.Zero {
				.ProgressBar {
					left: 35vw;
				}
			}
		}

		.Slides.Two2Three,
		.Slides.Four2Three {
			.Slide.Zero {
				.ProgressBar {
					left: 50vw;
				}
			}
		}

		.Slides.Three2Four {
			.YellowEllipse {
				opacity: 0;
			}

			.SliderBtn.NextSlide {
				opacity: 0;
				pointer-events: none;
			}

			.Slide.Zero {
				.ProgressBar {
					left: 65vw;
				}
			}
		}

		.Slide.One {
			.ScrollGIF {
				background-image: url("../Assets/Images/Map/Composer/Martinu.gif");
				transform: rotateY(180deg);
			}
		}
	}

	.Legacy {
		.Ellipse.Left {
			bottom: calc(var(--heightRatio) * -490vw);
		}

		.FooterSwitch {
			.Ellipse.Right {
				top: calc(var(--heightRatio) * 370vw);
			}
		}

		.FooterSwitch.Hover {
			.Ellipse.Right {
				width: 5vw;
				height: 5vw;
				right: 0vw;
				top: calc(var(--heightRatio) * 472.5vw);
			}
		}
	}
}

@media only screen and (max-width: 450px) {
	.ChapterMartinu.ZH-HANT {
		.Section.Five {
			height: 100vh !important;
		}

		.Section.FivePointOne {
			.Title {
				.Špalíček {
					width: 50vw !important;

					h1 {
						font-size: 7vw !important;
					}
				}
			}

			.RightHalf {
				.Paragraph.Three,
				.Paragraph.Four,
				.Paragraph.Six {
					width: 85vw !important;
				}
			}

			.Spotify {
				top: 122vh !important;
			}
		}

		.Section.FivePointTwo {
			.Quote {
				width: 92vw !important;

				p {
					font-size: 9vw !important;
					line-height: 9vw !important;
					text-align: initial !important;
				}
			}
		}

		.Slider .Slide p {
			width: 80vw !important;
		}
	}

	.ChapterMartinu {
		.Images.ChapterBG {
			left: 5vw !important;
			top: 15vh !important;
			width: 90vw !important;
			height: 126.7vw !important;
		}

		.ChapterCaption {
			bottom: 18vh !important;

			p {
				width: 80vw !important;
				padding-left: 10vw;
			}
		}

		.Section.One {
			.ChapterName {
				background-image: url("../Assets/Images/Martinu/Section1/ComposerName_m.png") !important;
			}
		}

		.Section.Two {
			.clipContent {
				.ImgContainer.Church {
					width: 72vw !important;
					height: 93vw !important;
					left: 14vw !important;
					top: 45vh !important;
				}

				.Paragraph.One {
					left: 7.5vw !important;
					top: 10vh !important;
					width: 85vw !important;

					h1 {
						text-align: center !important;
					}
				}

				.Caption.Church {
					padding-left: 0vw !important;
				}
			}
		}

		.Section.Four {
			h1 {
				font-size: 8vw !important;
				line-height: 9vw !important;
			}

			p {
				font-size: 4vw !important;
				line-height: 6.5vw !important;
				width: 85vw !important;
			}
		}

		.Section.Five {
			height: 110vh !important;

			.BGDots.RightDots {
				background-image: initial !important;
			}

			.Images.InParis {
				width: 100vw !important;
				height: 115vw !important;
			}

			.Caption.InParis {
				bottom: initial !important;
				right: 5vw !important;
				top: 92vw !important;
				width: 90vw !important;

				p {
					color: #ffffff;
				}
			}

			.Paragraph.Two {
				left: 7.5vw !important;
				top: 60vh !important;
				width: 85vw !important;
			}
		}

		.Section.FivePointOne {
			height: 160vh !important;

			.Title {
				left: 7.5vw !important;
				top: 3vh !important;

				.Špalíček {
					width: 23vw !important;
					height: 9vw !important;
					background-image: url("../Assets/Images/Martinu/Section5/Spalicek.png");
					background-repeat: no-repeat;
					background-size: contain;
				}
			}

			.ImgContainer.MartinuIllustration {
				width: 85vw !important;
				height: 97.7vw !important;
				top: 11vh !important;
				left: 7.5vw !important;

				.Images.MartinuIllustration {
					height: 97.7vw !important;
				}

				.Caption.MartinuIllustration {
					width: initial !important;
				}
			}

			.RightHalf {
				left: 7.5vw !important;
				top: 70vh !important;

				.Paragraph.Three {
					width: 85vw !important;
					margin-bottom: 4vh !important;
				}

				.Paragraph.Four {
					width: 85vw !important;
					margin-bottom: 4vh !important;
				}

				.Paragraph.Six {
					width: 85vw !important;
					margin-bottom: 4vh !important;
				}
			}

			.Spotify {
				left: 7.5vw !important;
				top: 135vh !important;
			}
		}

		.Section.FivePointTwo {
			height: 60vh !important;

			.QuoteMark {
				width: 13vw !important;
				height: 13vw !important;
				top: 9vh !important;
			}

			.QuoteMark.Close {
				top: 40vh !important;
			}

			.Quote {
				top: 11vh !important;
				width: 80vw;

				p {
					font-size: 15vw !important;
					line-height: 15vw !important;
				}
			}
		}

		.Section.FivePointThree {
			height: 110vh !important;

			.Images.ArriveUSA {
				width: 100vw !important;
				height: 119vw !important;
			}

			.Caption.ArriveUSA {
				bottom: initial !important;
				right: 5vw !important;
				top: 95vw !important;

				p {
					width: 90vw;
				}
			}

			.Paragraph.Seven {
				left: 7.5vw !important;
				top: 60vh !important;
				width: 85vw !important;
			}
		}

		.Legacy {
			.Ellipse.Left {
				bottom: -480vh !important;
			}

			.FooterSwitch {
				.Ellipse.Right {
					top: 360vh !important;
				}
			}
		}
	}
}
