// @import "./Chapter.scss";

.ChapterSmetana.ZH-HANT {
  .Section.Two .Quote.One p,
  .Section.FivePointFive .Quote.Two p {
    font-weight: 400 !important;
  }

  .Quote.Two {
    top: 27.5% !important;
  }

  .Section.Four {
    h1 {
      font-size: 2.5vw !important;
    }

    p {
      margin-left: 0 !important;
    }
  }

  .Section.Six .Paragraph.Three .Title {
    margin-top: 2vw !important;
  }

  .Section.Six .Paragraph.Three .Title {
    margin-bottom: 3vw !important;

    h1 {
      font-size: 3vw !important;
    }
  }

  .Slide.One {
    h1 {
      position: absolute;
      top: 26.5%;
    }

    p {
      position: absolute;
      top: 43%;
    }

    .MusicNote {
      position: absolute;
      top: 67%;
    }
  }

  .Slide.Three {
    h1 {
      position: absolute;
      top: 28.5%;
    }

    p {
      position: absolute;
      top: 45%;
    }

    .MusicNote {
      position: absolute;
      top: 65.3%;
    }
  }

  .Slide.Four {
    h1 {
      position: absolute;
      top: 21.5%;
    }

    p {
      position: absolute;
      top: 38%;
    }

    .MusicNote {
      position: absolute;
      top: 72%;
    }
  }
}

@media only screen and (max-width: 450px) {
  .ChapterSmetana.ZH-HANT {
    .Section.Four {
      h1 {
        font-size: 7vw !important;
      }
    }

    .Section.Two {
      .Quote.One {
        width: 100vw !important;

        p {
          text-align: center !important;
        }
      }
    }

    .Section.FivePointFive {
      .Quote.Two {
        width: 100vw !important;
        top: 15vh !important;

        p {
          text-align: initial !important;
        }
      }
    }

    .Section.Six {
      .Paragraph.Three {
        top: 75vh !important;

        .Title {
          h1 {
            font-size: 7vw !important;
          }
        }

        .Spotify {
          padding-top: 8vh !important;
        }
      }

      .Paragraph.Four {
        top: 130vh !important;
      }

      .Quote.Four.ZH {
        top: 145vh !important;

        p {
          font-size: 5vw !important;
          line-height: 7vw !important;
        }
      }

      .Paragraph.Five {
        top: 165vh !important;
      }
    }
  }

  .ChapterSmetana {
    // background-position: -100vw !important;
    background-size: 153.8vh 100vh !important;
    background-attachment: scroll !important;

    .Section.One {
      .ChapterName {
        background-image: url("../Assets/Images/Smetana/Section1/ComposerName_m.png") !important;
      }
    }

    .Section.Two {
      .Paragraph.One {
        top: 7.5vh !important;
        left: 7.5vw !important;

        p {
          width: 85vw !important;
        }
      }

      .Paragraph.Two {
        bottom: 7.5vh !important;
        right: 7.5% !important;

        p {
          width: 85vw !important;
        }
      }

      .Quote.One {
        top: 39vh !important;
        width: 85vw;
      }

      .QuoteMark {
        width: 11vw !important;
        height: 11vw !important;
        top: 40% !important;
      }

      .QuoteMark.Close {
        top: 60% !important;
      }
    }

    .Section.Four {
      h1 {
        font-size: 7vw !important;
        line-height: 8vw !important;
      }

      p {
        font-size: 4vw !important;
        line-height: 6.5vw !important;
        width: 85vw !important;
      }
    }

    .Slide.Two {
      .BarteredBrideBG {
        position: relative !important;
      }

      .Caption.BarteredBride {
        left: 10vw !important;
        bottom: 25vh !important;

        p {
          font-size: 3vw !important;
          text-align: center !important;
        }
      }
    }

    .Section.FivePointFive {
      height: 65vh !important;

      .Quote.Two {
        top: 10vh !important;
      }

      .QuoteMark {
        top: 11vh !important;
        width: 12vw !important;
        height: 12vw !important;
      }

      .QuoteMark.Close {
        top: 40vh !important;
      }

      .ImgContainer.Signature {
        width: 36vw !important;
        height: 15vw !important;
        top: 50vh !important;
        left: 55vw !important;
      }
    }

    .Section.Six {
      height: 250vh !important;

      .BGDots.LeftDots,
      .BGDots.RightDots {
        background-image: initial !important;
      }

      .ImgContainer.Poster {
        left: 12.5vw !important;
        width: 75vw !important;
        height: 125vw !important;

        .Images.Poster {
          height: 125vw !important;
        }
      }

      .Paragraph.Three {
        left: 7.5vw !important;
        top: 75vh !important;

        h1 {
          font-size: 10vw !important;
        }

        p {
          width: 85vw !important;
        }

        .Spotify {
          padding-top: 4vh !important;
        }
      }

      .Paragraph.Four {
        left: 7.5vw !important;
        top: 137vh !important;
        width: 85vw !important;
      }

      .Quote.Four {
        left: 7.5vw !important;
        top: 152vh !important;

        p {
          font-size: 7vw !important;
          line-height: 8vw !important;
          width: 85vw !important;
        }
      }

      .Paragraph.Five {
        left: 7.5vw !important;
        top: 170vh !important;
        width: 85vw !important;
      }

      .CharlesBridgeVyšehrad {
        width: 100vw !important;
        height: 120vw !important;
        right: 0 !important;
        top: 210vh !important;
      }
    }

    .Legacy {
      .Ellipse.Left {
        bottom: -415vh !important;
      }

      .FooterSwitch {
        .Ellipse.Right {
          top: 290vh !important;
        }
      }
    }
  }
}

.ChapterSmetana {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-image: url("../Assets/Images/Smetana/ProvisionalTheatre.jpg");
  background-size: cover;
  width: 100vw;
  overflow-x: hidden;

  .ChapterCaption {
    p {
      color: #ffffff;
    }
  }

  .Section.One {
    .ChapterName {
      background-image: url("../Assets/Images/Smetana/Section1/ComposerName.png");
    }
  }

  .Section.Two {
    .Paragraph.One {
      top: 12.5%;
      left: 12%;

      p {
        width: 40vw;
      }
    }

    .Paragraph.Two {
      bottom: 12.5%;
      right: 12%;

      p {
        width: 40vw;
      }
    }

    .Quote.One {
      top: 37%;
      left: 0%;

      p {
        font-size: 6vw;
        line-height: 6vw;
      }
    }

    .QuoteMark {
      width: 3.5vw;
      height: 3.5vw;
      position: absolute;
      left: -7%;
      top: 45%;
    }

    .QuoteMark.Close {
      left: 52%;
      top: 43%;
    }
  }

  .Section.Two.Active {
    .Quote.One {
      left: 25%;
    }

    .QuoteMark {
      left: 18%;
    }

    .QuoteMark.Close {
      left: 77%;
    }
  }

  .Section.Three {
    background-color: transparent;
    overflow: hidden;
  }

  .Section.Four {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    h1 {
      color: #ffffff;
      font-size: 2vw;
      font-weight: 600;
      line-height: 2.5vw;
      opacity: 0;
    }

    p {
      color: #ffffff;
      font-size: 1.25vw;
      line-height: 1.75vw;
      width: 30vw;
      opacity: 0;
    }
  }

  .Section.Four.Active {
    h1,
    p {
      animation: opacityChanging 3s forwards;
    }
  }

  .Section.Five {
    height: calc(var(--heightRatio) * 100vw);

    .ProgressBar {
      width: 12vw;
    }

    .Slides.One,
    .Slides.Two2One {
      .Slide.One {
        opacity: 1;
      }

      .SliderBtn.PrevSlide {
        opacity: 0;
        pointer-events: none;
      }

      .Slide.Zero {
        .ProgressBar {
          left: 20vw;
        }
      }
    }

    .Slides.One2Two,
    .Slides.Three2Two {
      .Slide.Zero {
        .ProgressBar {
          left: 32vw;
        }

        .SliderBtn {
          background-image: url("../Assets/Images/Chapter/Slider/Arrow_grey.png");
        }
      }
    }

    .Slides.Two2Three,
    .Slides.Four2Three {
      .Slide.Zero {
        .ProgressBar {
          left: 44vw;
        }
      }
    }

    .Slides.Three2Four,
    .Slides.Five2Four {
      .Slide.Zero {
        .ProgressBar {
          left: 56vw;
        }
      }
    }

    .Slides.Four2Five {
      .SliderBtn {
        background-image: url("../Assets/Images/Chapter/Slider/Arrow_grey.png");
      }

      .SliderBtn.NextSlide {
        opacity: 0;
        pointer-events: none;
      }

      .Slide.Zero {
        .ProgressBar {
          left: 68vw;
        }
      }

      .Slide.Five {
        z-index: 10;
        pointer-events: all;
      }
    }

    .Slide.One {
      .ScrollGIF {
        background-image: url("../Assets/Images/Map/Composer/Smetana.gif");
        transform: rotateY(180deg);
      }
    }

    .Slide.Two {
      background-color: #000000;

      p {
        font-family: acumin-pro, sans-serif;
      }

      .BarteredBrideBG {
        width: 100vw;
        height: calc(var(--heightRatio) * 100vw);
        position: absolute;
        top: 0;
        left: 0;
        background-image: url("../Assets/Images/Smetana/Section5/BarteredBride.jpg");
        background-size: cover;
        background-repeat: no-repeat;
      }

      .Images.BarteredBride {
        left: calc(100 - var(--heightRatio) * 1.6 * 50vw);
        width: calc(var(--heightRatio) * 1.6 * 100vw);
        height: calc(var(--heightRatio) * 100vw);
        z-index: 0;

        img {
          z-index: 0;
        }
      }

      .Caption.BarteredBride {
        position: absolute;
        left: 36vw;
        bottom: calc(var(--heightRatio) * 13vw);

        p {
          color: #ffffff;
          font-size: 1.15vw;
          text-align: center;
        }
      }
    }

    .Slide.Five {
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
    }
  }

  .Section.FivePointFive {
    .Quote.Two {
      left: 18%;
      top: 20%;

      p {
        line-height: 8vw;
        font-size: 8vw;
      }
    }

    .QuoteMark {
      left: 10%;
      top: 35%;
      width: 8vw;
      height: 8vw;
    }

    .QuoteMark.Close {
      left: 67%;
      top: 45%;
    }

    .ImgContainer.Signature {
      width: 18vw;
      height: 8vw;
      left: 74%;
      bottom: 28%;
    }
  }

  .Section.Six {
    height: calc(var(--heightRatio) * 200vw);
    overflow-x: hidden;

    .BGDots.LeftDots {
      width: 24vw;
      height: 51vw;
      left: -1.7%;
      top: calc(var(--heightRatio) * 45vw);
      transform: rotateY(180deg);
    }

    .BGDots.RightDots {
      width: 25vw;
      height: 50vw;
      right: -1vw;
      top: 0;
    }

    .ImgContainer.Poster {
      left: 12%;
      top: calc(var(--heightRatio) * 15vw);
      width: 25%;
      height: 41.5vw;

      .Images.Poster {
        width: 100%;
        height: 41.5vw;
      }
    }

    .Paragraph.Three {
      right: 12%;
      top: calc(var(--heightRatio) * 10vw);

      h1,
      p {
        margin: 0;
      }

      h1 {
        font-family: adorn-coronet, sans-serif;
        font-size: 4vw;
        font-weight: 300;
      }

      p {
        font-size: 1.25vw;
        line-height: 1.75vw;
        width: 40vw;
      }

      .Spotify {
        padding-top: 3vw;
      }
    }

    .Paragraph.Four {
      left: 12vw;
      top: calc(var(--heightRatio) * 110vw);
      width: 25vw;
    }

    .Quote.Four {
      left: 12vw;
      top: calc(var(--heightRatio) * 129vw);

      p {
        font-weight: 100;
        width: 40vw;
      }
    }

    .Quote.Four.ZH {
      top: calc(var(--heightRatio) * 131vw);

      p {
        font-size: 1.5vw;
        line-height: 1.75vw;
      }
    }

    .Paragraph.Five {
      left: 12vw;
      top: calc(var(--heightRatio) * 150vw);
      width: 40vw;
    }

    .CharlesBridgeVyšehrad {
      background-image: url("../Assets/Images/Smetana/Section6/CharlesBridgeVyšehrad.png");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      width: 52vw;
      height: calc(var(--heightRatio) * 120vw);
      right: 5%;
      top: calc(var(--heightRatio) * 90vw);
    }
  }

  .Legacy {
    .Ellipse.Left {
      bottom: calc(var(--heightRatio) * -420vw);
    }

    .FooterSwitch {
      .Ellipse.Right {
        top: calc(var(--heightRatio) * 300vw);
        animation: SmetanafooterHoverOut 2s forwards;
      }
    }
  }

  .FooterSwitch.Hover {
    .FooterName {
      opacity: 1;
    }

    .Ellipse.Right {
      width: 5vw;
      height: 5vw;
      right: 0vw;
      top: calc(var(--heightRatio) * 402.5vw);
    }
  }

  .Section.Six,
  .Section.Seven {
    overflow: hidden;
    /* this will hide the scrollbar in mozilla based browsers */
    overflow: -moz-scrollbars-none;
    /* this will hide the scrollbar in internet explorers */
    -ms-overflow-style: none;
  }

  .Section.Six::-webkit-scrollbar,
  .Section.Seven::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
}
