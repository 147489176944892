// @import "./Chapter.scss";

.ChapterDvorak.ZH-HANT {
	.Quote.One p {
		font-size: 1.5vw !important;
		line-height: 1.75vw !important;
		text-align: initial;
		width: 35vw;
	}

	.Section.Four .Title h1 {
		font-size: 3vw;
		line-height: 3vw;
	}

	.Section.Four .RightHalf .Paragraph.Three .Quote p {
		font-weight: 100;
	}

	.Section.Four .Spotify {
		top: calc(var(--heightRatio) * 42vw) !important;
	}

	.Caption.RůženaMaturová {
		left: 27.5vw !important;
	}

	.Section.FourPointOne .Quote.Two p {
		font-weight: 400 !important;
	}

	.Slide.One {
		h1 {
			position: absolute;
			top: 25.5%;
		}

		p {
			position: absolute;
			top: 41.5%;
		}

		.MusicNote {
			position: absolute;
			top: 68.8%;
		}
	}

	.Slide.Two {
		h1 {
			position: absolute;
			top: 25%;
		}

		p {
			position: absolute;
			top: 41.5%;
		}

		.MusicNote {
			position: absolute;
			top: 68.8%;
		}
	}

	.Slide.Five {
		h1 {
			position: absolute;
			top: 24%;
		}

		p {
			position: absolute;
			top: 39%;
		}

		.MusicNote {
			position: absolute;
			top: 70.2%;
		}
	}
}

.ChapterDvorak {
	overflow-x: hidden;

	.Section.One {
		.ChapterName {
			background-image: url("../Assets/Images/Dvorak/Section1/ComposerName.png");
		}
	}

	.Section.Two {
		.clipContent {
			flex-direction: column;
			flex: 1;

			.Paragraph {
				h1 {
					font-size: 2vw;
				}
				P {
					width: 38.5vw;
				}
			}

			.Paragraph.One {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				flex: 1;
				padding-top: 6vw;
			}

			.Paragraph.Two {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				flex: 1;
				padding-bottom: 8vw;
			}
		}
	}

	.Section.Three {
		.Images.NewYorkPhoto {
			width: 50vw;
			height: calc(var(--heightRatio) * 100vw);
		}

		.Caption.NewYorkPhoto {
			position: absolute;
			right: 52vw;
			bottom: calc(var(--heightRatio) * 12vw);
			z-index: 10;

			p {
				color: #ffffff;
				padding: 0.5vw;
				border-radius: 0.75vw;
				background-color: rgba(0, 0, 0, 0.5);
			}
		}

		.Paragraph.One {
			left: 60%;
			top: 10%;

			p {
				width: 31vw;
			}
		}

		.BGDots.RightDots {
			width: 20vw;
			height: 40vw;
			right: -1vw;
			top: 0;
		}
	}

	.Section.Four {
		height: calc(var(--heightRatio) * 140vw);

		.Title {
			margin-bottom: 3vw;

			h1 {
				font-size: 4vw;
				line-height: 4vw;
			}
		}

		.Paragraph.Zero {
			position: absolute;
			left: 15vw;
			top: calc(var(--heightRatio) * 12vw);
			width: 70vw;
		}

		.ImgContainer.SlavonicDances {
			width: 30vw;
			height: calc(var(--heightRatio) * 68.5vw);
			left: 15vw;
			top: calc(var(--heightRatio) * 40vw);

			.Images.SlavonicDances {
				width: 100%;
				height: calc(var(--heightRatio) * 68.5vw);
			}
		}

		.Caption.SlavonicDances {
			width: 30vw;
		}

		.RightHalf {
			position: absolute;
			width: 50%;
			left: 52.5vw;
			top: calc(var(--heightRatio) * 39vw);

			.Paragraph.Three {
				width: 32.5vw;

				.Quote {
					p {
						font-size: 2.5vw;
						line-height: 2.5vw;
					}
				}
			}

			.Quote.One {
				position: absolute;
				top: calc(var(--heightRatio) * 22vw);
				margin-top: 1.5vw;

				p {
					margin: 0;
				}
			}

			.Spotify {
				position: absolute;
				top: calc(var(--heightRatio) * 48vw);
			}
		}

		.Paragraph.Four {
			left: 15%;
			top: calc(var(--heightRatio) * 118vw);
			width: 70vw;
		}

		.BGDots.RightDots {
			width: 18vw;
			height: 38vw;
			right: -1vw;
			top: calc(var(--heightRatio) * 71.5vw);
		}
	}

	.Section.FourPointOne {
		.QuoteMark {
			left: 12%;
			top: calc(var(--heightRatio) * 30.5vw);
			width: 6vw;
			height: 6vw;
		}

		.QuoteMark.Close {
			left: 68%;
			top: calc(var(--heightRatio) * 52vw);
		}

		.Quote.Two {
			left: 21%;
			top: calc(var(--heightRatio) * 16.5vw);

			p {
				line-height: 7vw;
				font-size: 7vw;
			}
		}

		.ImgContainer.Signature {
			left: 65%;
			top: calc(var(--heightRatio) * 65vw);
			width: 25vw;
		}

		.BGDots.LeftDots {
			width: 24vw;
			height: 51vw;
			left: -1.7%;
			top: calc(var(--heightRatio) * 7.5vw);
			transform: rotateY(180deg);
		}
	}

	.Section.FourPointTwo {
		.Paragraph.Five {
			left: 18%;
			top: calc(var(--heightRatio) * 20vw);
			width: 35vw;
		}

		.Paragraph.Six {
			left: 47%;
			top: calc(var(--heightRatio) * 62vw);
			width: 35vw;
		}

		.DvořákMemorial {
			position: absolute;
			background-image: url("../Assets/Images/Dvorak/Section4/DvořákMemorial.png");
			background-repeat: no-repeat;
			background-size: contain;
			left: 58%;
			top: calc(var(--heightRatio) * 17vw);
			width: 24vw;
			height: 24vw;
		}

		.DvorakMini {
			position: absolute;
			background-image: url("../Assets/Images/Map/Composer/Dvorak_mini.png");
			background-repeat: no-repeat;
			background-size: contain;
			left: 22%;
			top: calc(var(--heightRatio) * 55vw);
			width: 17vw;
			height: 14vw;
		}
	}

	.Section.Five {
		height: calc(var(--heightRatio) * 100vw);

		.ProgressBar {
			width: 12vw;
		}

		.Slides.One,
		.Slides.Two2One {
			.Slide.One {
				opacity: 1;
			}

			.SliderBtn.PrevSlide {
				opacity: 0;
				pointer-events: none;
			}

			.Slide.Zero {
				.ProgressBar {
					left: 20vw;
				}
			}
		}

		.Slides.One2Two,
		.Slides.Three2Two {
			.Slide.Zero {
				.ProgressBar {
					left: 32vw;
				}
			}
		}

		.Slides.Two2Three,
		.Slides.Four2Three {
			.Slide.Zero {
				.SliderBtn {
					background-image: url("../Assets/Images/Chapter/Slider/Arrow_grey.png");
				}

				.ProgressBar {
					left: 44vw;
				}
			}
		}

		.Slides.Three2Four,
		.Slides.Five2Four {
			.Slide.Zero {
				.SliderBtn {
					background-image: url("../Assets/Images/Chapter/Slider/Arrow_grey.png");
				}

				.ProgressBar {
					left: 56vw;
				}
			}

			.Slide.Four {
				z-index: 10;
			}
		}

		.Slides.Four2Five {
			.SliderBtn.NextSlide {
				opacity: 0;
				pointer-events: none;
			}

			.Slide.Zero {
				.ProgressBar {
					left: 68vw;
				}
			}

			.Slide.Five {
				z-index: 10;
				pointer-events: all;
			}
		}

		.Slide.One {
			left: 0;
			top: 0;

			.ScrollGIF {
				background-image: url("../Assets/Images/Map/Composer/Dvorak.gif");
			}
		}

		.Slide.Three {
			background-color: #000000;

			p {
				font-family: acumin-pro, sans-serif;
			}

			.ImgContainer.RůženaMaturová {
				top: calc(50 - calc(var(--heightRatio) * 40vw));
				left: calc(100 - var(--heightRatio) * 1.6 * 60vw);
				width: calc(var(--heightRatio) * 1.6 * 40vw);
				height: calc(var(--heightRatio) * 80vw);
				z-index: 0;

				img {
					z-index: 0;
				}
			}

			.Caption.RůženaMaturová {
				position: absolute;
				bottom: calc(var(--heightRatio) * 5vw);
				left: 23vw;

				p {
					color: #ffffff;
					text-align: center;
					width: auto;
				}
			}
		}

		.Slide.Four {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.Slide.Five .FooterSwitch .FooterBtn p {
			width: auto;
		}
	}
}

@media only screen and (max-width: 450px) {
	.ChapterDvorak.ZH-HANT {
		.Section.Two {
			h1 {
				width: 80vw !important;
			}
		}

		.Section.Four {
			.Title h1 {
				font-size: 7vw !important;
			}

			.RightHalf .Quote.One {
				margin-top: 3vh !important;

				p {
					width: 85vw !important;
					font-size: 5vw !important;
					line-height: 7vw !important;
				}
			}
		}

		.Section.FourPointOne {
			.Quote.Two {
				top: 10vh !important;

				p {
					text-align: initial !important;
				}
			}

			.ImgContainer.Signature {
				top: 38vh !important;
			}
		}
	}

	.ChapterDvorak {
		.Section.One {
			.ChapterName {
				background-image: url("../Assets/Images/Dvorak/Section1/ComposerName_m.png") !important;
			}
		}

		.Section.Two {
			h1 {
				text-align: center !important;
			}

			p {
				width: 85vw !important;
			}
		}

		.Section.Three {
			height: 110vh !important;

			.Images.NewYorkPhoto {
				width: 100vw !important;
				height: 55vh !important;
			}

			.Caption.NewYorkPhoto {
				bottom: initial !important;
				right: 5vw !important;
				top: 100vw !important;
				width: 90vw !important;

				p {
					padding: 1.5vw !important;
					border-radius: 2.25vw !important;
				}
			}

			.Paragraph.One {
				left: 7.5vw !important;
				top: 60vh !important;

				p {
					width: 85vw !important;
				}
			}

			.BGDots.RightDots {
				width: 50vw !important;
				height: 100vw !important;
				right: -2vw !important;
				top: 55vh !important;
			}
		}

		.Section.Four {
			height: 200vh !important;

			.Title {
				margin-bottom: 3vw;

				h1 {
					font-size: 10vw !important;
					line-height: 10vw !important;
				}
			}

			.Paragraph.Zero {
				left: 7.5vw !important;
				top: 2.5vh !important;
				width: 85vw !important;

				p {
					margin: 4vh 0;
				}
			}

			.ImgContainer.SlavonicDances {
				width: 80vw !important;
				height: 103vw !important;
				left: 10vw !important;
				top: 40vh !important;

				.Images.SlavonicDances {
					height: 103vw !important;
				}
			}

			.Caption.SlavonicDances {
				width: 80vw !important;
			}

			.RightHalf {
				width: 85vw !important;
				left: 7.5vw !important;
				top: 98vh !important;

				.Paragraph.Three {
					width: 85vw !important;
				}

				.Quote.One {
					top: 23vh !important;

					p {
						font-size: 7vw !important;
						line-height: 8vw !important;
					}
				}

				.Spotify {
					top: 46vh !important;
				}
			}

			.Paragraph.Four {
				left: 7.5vw !important;
				width: 85vw !important;
				top: 170vh !important;
			}
		}

		.Section.FourPointOne {
			height: 55vh !important;

			.Quote.Two {
				top: 6vh !important;
				width: 85vw !important;

				p {
					text-align: initial !important;
				}
			}

			.QuoteMark {
				width: 12vw !important;
				height: 12vw !important;
				top: 3vh !important;
			}

			.QuoteMark.Close {
				top: 36vh !important;
			}

			.ImgContainer.Signature {
				left: 40vw !important;
				top: 45vh !important;
				width: 50vw !important;
			}

			.BGDots.LeftDots {
				width: 48vw !important;
				height: 102vw !important;
				top: -15vh !important;
				z-index: 100;
			}
		}

		.Section.FourPointTwo {
			height: 140vh !important;

			.Paragraph.Five {
				left: 7.5vw !important;
				top: 3vh !important;
				width: 85vw !important;

				p {
					padding-top: 45vh !important;
				}
			}

			.DvořákMemorial {
				left: 14vw !important;
				top: 15vh !important;
				width: 72vw !important;
				height: 72vw !important;
			}

			.DvorakMini {
				left: 20vw !important;
				top: 80vh !important;
				width: 68vw !important;
				height: 56vw !important;
			}

			.Paragraph.Six {
				left: 7.5vw !important;
				top: 115vh !important;
				width: 85vw !important;
			}
		}

		.Section.Five {
			.Slide.Three {
				.ImgContainer.RůženaMaturová {
					top: 15vh !important;
					left: 0 !important;
					width: 100vw !important;
					height: 118vw !important;
				}

				.Caption.RůženaMaturová {
					bottom: 18vh !important;
					left: 10vw !important;

					p {
						width: 80vw !important;
						font-size: 3.25vw !important;
						line-height: 3.75vw !important;
					}
				}
			}
		}
	}
}
