// @import url("https://use.typekit.net/vcm1gnt.css");

:root {
	--heightRatio: 1041 / 1792;
}

body {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.ZH-HANT {
	.Paragraph {
		position: absolute;

		h1 {
			font-size: 2.5vw !important;
		}
	}

	p {
		font-family: noto-sans-cjk-jp, sans-serif !important;
	}

	.ChapterName,
	.Quote {
		p {
			font-family: yu-mincho-pr6, sans-serif !important;
			font-weight: 600;
		}
	}

	.ChapterName.ZH {
		width: auto !important;
		background-image: initial !important;

		p {
			margin-top: 1.5vw;
			font-weight: 400;
		}
	}

	.ChapterYear {
		top: 57% !important;

		p {
			font-size: 2vw !important;
		}
	}

	h1 {
		font-family: noto-sans-cjk-jp, sans-serif !important;
	}

	.Slider {
		.Slide {
			h1 {
				font-size: 3vw;
				margin: 2vw;
			}

			p {
				width: 30vw;
				text-align: justify;
				text-justify: inter-character;
			}
		}
	}

	.VideoMask p {
		line-height: 3vw !important;
		width: 58vw !important;
	}
}

.ChapterCaption {
	position: fixed;
	right: 2.5vw;
	bottom: calc(var(--heightRatio) * 2.5vw);

	p {
		font-family: acumin-pro, sans-serif;
		margin: 0;
	}
}

.ChapterName,
.Quote {
	position: absolute;

	p {
		font-family: adorn-coronet, sans-serif;
	}
}

.Quote {
	transition: all 1.5s;

	p {
		color: #192d42;
		font-size: 2.5vw;
		line-height: 2.5vw;
	}
}

.ImgContainer {
	position: absolute;
}

.Images {
	overflow: hidden;
}

img {
	width: 100%;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
}

h1 {
	font-family: acumin-pro, sans-serif;
	font-weight: 700;
	font-size: 2.5vw;
}

p {
	font-family: acumin-pro, sans-serif;
	font-weight: 400;
	font-size: 1.25vw;
	line-height: 1.75vw;
	text-align: justify;
	text-justify: inter-character;
}

.Ellipse {
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	z-index: -10;
	transition: all 1s;
}

.Paragraph {
	position: absolute;

	h1 {
		font-size: 2vw;
	}
	p {
		font-size: 1.25vw;
		line-height: 1.75vw;
	}
}

.Caption {
	align-items: center;
	justify-content: center;

	p {
		margin: 0;
		color: #808080;
		line-height: 1.5vw;
		font-size: 1vw;
	}
}

.DotsRect {
	position: absolute;
	width: 90%;
	height: 90%;
	top: 5%;
	left: 5%;
	background-image: url("../Assets/Images/Dot.png");
	background-size: 2%;
	background-repeat: round;
	mix-blend-mode: luminosity;
}

.BGDots {
	background-image: url("../Assets/Images/Chapter/Dots.png");
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	// mix-blend-mode: luminosity;
}

.QuoteMark {
	background-image: url("../Assets/Images/Chapter/QuoteMark.png");
	background-size: contain;
	background-repeat: no-repeat;
	width: 5vw;
	height: 5vw;
	position: absolute;
	transition: all 1.5s;
}

.QuoteMark.Close {
	transform: rotateX(180deg) rotateY(180deg);
}

.BGDots {
	background-image: url("../Assets/Images/Chapter/Dots.png");
}

.Video {
	width: 100vw;
	height: calc(var(--heightRatio) * 100vw);
	position: absolute;
	top: 0%;
	left: 0%;
}

.Title {
	display: flex;
	flex-direction: row;
	align-items: center;

	h1 {
		font-family: adorn-coronet, sans-serif;
		font-weight: 400;
		font-size: 3vw;
		margin: 0;
	}

	.MusicNotes {
		background-image: url("../Assets/Images/Chapter/MusicNotes.png");
		background-repeat: no-repeat;
		background-size: contain;
		width: 6vw;
		height: 3vw;
		margin-left: 2.5vw;
	}
}

.VideoMask {
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100vw;
	height: calc(var(--heightRatio) * 100vw);
	position: absolute;
	z-index: 100;
	transition: all 1s;

	p {
		color: #ffffff;
		font-family: acumin-pro, sans-serif;
		font-weight: 600;
		font-size: 2vw;
		line-height: 2.5vw;
		text-align: justify;
		text-justify: inter-character;
		width: 68vw;
	}
}

.VideoMaskBtn {
	width: 5vw;
	height: 5vw;
	background-image: url("../Assets/Images/Chapter/VideoMaskBtn.png");
	background-repeat: no-repeat;
	background-size: contain;
	cursor: pointer;
}

.VideoContainer {
	width: 100vw;
	height: calc(var(--heightRatio) * 100vw);
	background-color: #000000;
}

.ScrollMagicWrapper {
	width: 100vw;
	height: calc(100vh + 50vw);
	overflow: hidden;
}

.Section {
	background-color: #ffffff;
	width: 100vw;
	height: calc(var(--heightRatio) * 100vw);
	position: relative;
	z-index: 0;
	white-space: pre-line;
}

.ZH-HANT .Section.One {
	cursor: url("../Assets/Images/ClickCursor_ZH.png"), pointer;
}

.Section.One {
	cursor: url("../Assets/Images/ClickCursor.png"), pointer;

	.ChapterName {
		top: 47%;
		right: 18%;
		width: 35vw;
		height: 5.5vw;
		animation: opacityChanging 3s;

		background-repeat: no-repeat;
		background-size: contain;

		p {
			font-size: 5.7vw;
			color: #192d42;
		}
	}

	.ChapterName.ZH {
		right: 22.5%;

		p {
			font-size: 4vw;
			color: #192d42;
		}
	}

	.ChapterYear {
		position: absolute;
		top: 59%;
		right: 22.5%;

		p {
			font-family: adorn-coronet, sans-serif;
			font-size: 4vw;
			color: #192d42;
		}
	}

	h1,
	p {
		margin: 0;
	}

	.ImgContainer.Portrait {
		width: 24vw;
		height: 29vw;
		left: 20%;
		bottom: 20%;
		animation: opacityChanging 3s;
	}

	.Ellipse.Green {
		background-image: url("../Assets/Images/Chapter/Section1/GreenEllipse.png");
		width: 68vw;
		height: 68vw;
		top: -25%;
		left: -12%;
		transform: rotate(-15deg);
		animation: EllipseOpacity 1.5s forwards;
	}

	.Ellipse.Blue {
		background-image: url("../Assets/Images/Chapter/Section1/BlueEllipse.png");
		width: 38vw;
		height: 38vw;
		top: 13%;
		right: -2.5%;
		animation: EllipseOpacity 1.5s forwards;
	}
}

.clipContent {
	width: 100vw;
	height: calc(var(--heightRatio) * 100vw);
	position: relative;
	overflow: hidden;
	opacity: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Section.Two {
	background-color: #fff7ed;

	h1 {
		font-weight: 600;
	}

	.Ellipse.Blue {
		background-image: url("../Assets/Images/Chapter/Section2/BlueEllipse.png");
		width: 70vw;
		height: calc(var(--heightRatio) * 120vw);
		left: -15%;
		bottom: -101%;
	}

	.Ellipse.Green {
		background-image: url("../Assets/Images/Chapter/Section2/GreenEllipse.png");
		width: 30vw;
		height: calc(var(--heightRatio) * 50vw);
		right: -5%;
		top: 44.5%;
	}
}

.Section.Two.Active {
	.clipContent {
		animation: opacityChanging 3s forwards;
	}
}

.Slider {
	width: 100vw;
	display: flex;

	.SlidesContainer {
		width: 100vw;
		height: calc(var(--heightRatio) * 100vw);
		overflow: hidden;
	}

	.ProgressBarBG {
		width: 60vw;
		height: 0.5vw;
		background-color: #ced6db;
		position: absolute;
		bottom: calc(var(--heightRatio) * 10vw);
		left: 20vw;
		border-radius: 15%;
		z-index: 100;
	}

	.ProgressBar {
		height: 0.5vw;
		background-color: #5bbbaf;
		position: absolute;
		bottom: calc(var(--heightRatio) * 10vw);
		border-radius: 15%;
		z-index: 100;
		transition: all 0.5s;
		cursor: grab;
	}

	.ProgressBar:hover {
		transform: scale(1.1);
		// transition: opacity 0s;
	}

	.ProgressBar:-moz-drag-over {
		cursor: grabbing;
	}

	.SliderBtn {
		width: 3vw;
		height: 3vw;
		position: absolute;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url("../Assets/Images/Chapter/Slider/Arrow.png");
		z-index: 100;
		cursor: pointer;
		transition: all 0.5s;
	}

	.PrevSlide {
		left: 15vw;
		bottom: calc(var(--heightRatio) * 8vw);
	}

	.NextSlide {
		right: 15vw;
		bottom: calc(var(--heightRatio) * 8vw);
		transform: rotateY(180deg);
	}

	.Slides {
		position: absolute;
		top: 0;
		display: flex;
		flex-direction: row;
		width: 100vw;
	}

	.Slide.One,
	.Slide.Two,
	.Slide.Three,
	.Slide.Four,
	.Slide.Five {
		z-index: -50;
	}

	.Slides.One {
		.Slide.One {
			z-index: 50;
		}
	}

	.Slides.One2Two {
		.Slide.Zero {
			.YellowEllipse {
				animation: slideZeroResize 1s;
			}
		}

		.Slide.One {
			animation: slideDisappear 0.75s forwards;
		}

		.Slide.Two {
			animation: slideAppear 1.5s forwards;
			z-index: 50;
		}
	}

	.Slides.Two2Three {
		.Slide.Zero {
			.YellowEllipse {
				animation: slideZeroResize2 1s;
			}
		}

		.Slide.Two {
			animation: slideDisappear 0.75s forwards;
		}

		.Slide.Three {
			animation: slideAppear 1.5s forwards;
			z-index: 50;
		}
	}

	.Slides.Three2Four {
		.Slide.Zero {
			.YellowEllipse {
				animation: slideZeroResize 1s;
			}
		}

		.Slide.Three {
			animation: slideDisappear 0.75s forwards;
		}

		.Slide.Four {
			animation: slideAppear 1.5s forwards;
			z-index: 50;
		}
	}

	.Slides.Four2Five {
		.Slide.Zero {
			.YellowEllipse {
				animation: slideZeroResize2 1s;
			}
		}

		.Slide.Four {
			animation: slideDisappear 0.75s forwards;
		}

		.Slide.Five {
			animation: slideAppear 1.5s forwards;
			z-index: 50;
		}
	}

	.Slides.Two2One {
		.Slide.Zero {
			.YellowEllipse {
				animation: slideZeroResize2 1s;
			}
		}

		.Slide.Two {
			animation: slideDisappear 0.75s forwards;
		}

		.Slide.One {
			animation: slideAppear 1.5s forwards;
			z-index: 50;
		}

		.SliderBtn.PrevSlide {
			opacity: 0;
			pointer-events: none;
		}
	}

	.Slides.Three2Two {
		.Slide.Zero {
			.YellowEllipse {
				animation: slideZeroResize 1s;
			}
		}

		.Slide.Three {
			animation: slideDisappear 0.75s forwards;
		}

		.Slide.Two {
			animation: slideAppear 1.5s forwards;
			z-index: 50;
		}
	}

	.Slides.Four2Three {
		.Slide.Zero {
			.YellowEllipse {
				animation: slideZeroResize2 1s;
			}
		}

		.Slide.Four {
			animation: slideDisappear 0.75s forwards;
		}

		.Slide.Three {
			animation: slideAppear 1.5s forwards;
			z-index: 50;
		}
	}

	.Slides.Five2Four {
		.Slide.Zero {
			.YellowEllipse {
				animation: slideZeroResize 1s;
			}
		}

		.Slide.Five {
			animation: slideDisappear 0.75s forwards;
		}

		.Slide.Four {
			animation: slideAppear 1.5s forwards;
			z-index: 50;
		}
	}

	.Slide {
		height: calc(var(--heightRatio) * 100vw);
		width: 100vw;
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
		justify-content: center;
		position: absolute;
		opacity: 0;
		transition: 1s;
		overflow: hidden;

		h1 {
			font-family: adorn-coronet, sans-serif;
			font-size: 4vw;
			font-weight: 100;
			margin: 0;
		}

		p {
			line-height: 2vw;
			font-size: 1.25vw;
			text-align: center;
		}

		.MusicNote {
			background-image: url("../Assets/Images/Chapter/MusicNote.png");
			background-repeat: no-repeat;
			background-size: contain;
			width: 1.5vw;
			height: 1.5vw;
			margin-top: 2.5vw;
		}
	}

	.Slide.Zero {
		width: 100vw;
		height: calc(var(--heightRatio) * 100vw);
		overflow: hidden;
		opacity: 1;
		background-color: #e3e3e3;
		background-repeat: no-repeat;
		background-size: cover;

		justify-content: center;
		align-items: center;

		.YellowEllipse {
			width: 60vw;
			height: 60vw;
			position: absolute;
			left: 20vw;
			background-image: url("../Assets/Images/Chapter/Slider/YellowEllipse.png");
			background-size: contain;
			background-repeat: no-repeat;
			transition: all 1.5s;
			top: calc((var(--heightRatio) - 0.6) / 2 * 100vw);
		}
	}

	.Slide.One {
		.ScrollGIF {
			width: 5vw;
			height: 8vw;
			position: absolute;
			right: 5vw;
			bottom: 5vw;

			background-repeat: no-repeat;
			background-size: contain;
		}

		.Scroll {
			width: 4vw;
			height: 4vw;
			position: absolute;
			right: 5vw;
			bottom: 10vw;

			background-image: url("../Assets/Images/Chapter/Click.png");
			background-repeat: no-repeat;
			background-size: contain;
		}

		.FlippedClick {
			width: 4vw;
			height: 4vw;
			position: absolute;
			right: 10vw;
			bottom: 5.8vw;

			background-image: url("../Assets/Images/Chapter/Slider/FlippedClick.png");
			background-repeat: no-repeat;
			background-size: contain;
		}
	}
}

.Legacy {
	width: 100vw;
	height: calc(var(--heightRatio) * 100vw);
	position: relative;
	z-index: 0;
	white-space: pre-line;
	background-color: #f0f0f0;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	h1 {
		font-weight: 600;
		z-index: 10;
	}

	p {
		text-align: justify;
		text-justify: inter-character;
		width: 31vw;
		z-index: 10;
	}

	.Ellipse.Left {
		background-image: url("../Assets/Images/Chapter/Legacy/LeftEllipse.png");
		width: 40vw;
		height: 40vw;
		left: -7.5vw;
	}

	.Ellipse.Right {
		background-image: url("../Assets/Images/Chapter/Legacy/RightEllipse.png");
		width: 45vw;
		height: 45vw;
		right: -10vw;
	}

	.Ellipse.LowerRight {
		background-image: url("../Assets/Images/Chapter/Legacy/RightEllipse.png");
		width: 5vw;
		height: 5vw;
		right: 3%;
		bottom: 3%;
	}
}

.FooterSwitch {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 3%;
	bottom: calc(var(--heightRatio) * 3vw);
	// width: 15vw;
	// height: 5vw;

	p {
		width: initial;
		white-space: nowrap;
		position: static !important;
	}

	.FooterBtn {
		.FooterArrow {
			background-image: url("../Assets/Images/Chapter/FooterArrow.png");
			background-size: contain;
			background-repeat: no-repeat;
			width: 1.75vw;
			height: 1vw;
			margin-left: 1vw;
			margin-top: 0.5vw;
			transition: all 1s;
			cursor: pointer;
		}

		.FooterName {
			position: absolute;
			right: 0%;
			bottom: 0vw;
			transition: all 1s;
			opacity: 0;
			cursor: pointer;

			p {
				font-family: acumin-pro, sans-serif;
				font-size: 1.75vw;
				line-height: 1.75vw;
				font-weight: 600;
			}
		}

		.NextChapter {
			position: absolute;
			right: 0vw;
			bottom: 0vw;
			transition: all 1s;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			opacity: 1;

			p {
				font-family: acumin-pro, sans-serif;
				font-size: 1.75vw;
				line-height: 1.75vw;
				font-weight: 600;
			}
		}
	}

	.Ellipse.Right {
		background-image: url("../Assets/Images/Chapter/Legacy/RightEllipse.png");
		width: 45vw;
		height: 45vw;
		right: -13vw;
	}
}

.FooterSwitch.Hover {
	.FooterArrow {
		opacity: 0;
	}

	.FooterName {
		// right: 32%;
		opacity: 1;
	}

	.NextChapter {
		opacity: 0;
	}
}

@keyframes opacityChanging {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes EllipseOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes GreenEllipseFloating {
	0% {
		top: 70%;
	}
	100% {
		top: -25%;
	}
}

@keyframes BlueEllipseFloating {
	0% {
		top: 90%;
	}
	100% {
		top: 8%;
	}
}

@keyframes slideDisappear {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes slideAppear {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes slideZeroResize {
	0% {
		width: 60vw;
		height: 60vw;
		left: 20vw;
		top: calc((var(--heightRatio) - 0.6) / 2 * 100vw);
	}
	33% {
		width: 40vw;
		height: 40vw;
		left: 30vw;
		top: calc((var(--heightRatio) - 0.4) / 2 * 100vw);
	}
	66% {
		width: 80vw;
		height: 80vw;
		left: 10vw;
		top: calc((var(--heightRatio) - 0.8) / 2 * 100vw);
	}
	100% {
		width: 60vw;
		height: 60vw;
		left: 20vw;
		top: calc((var(--heightRatio) - 0.6) / 2 * 100vw);
	}
}

@keyframes slideZeroResize2 {
	0% {
		width: 60vw;
		height: 60vw;
		left: 20vw;
		top: calc((var(--heightRatio) - 0.6) / 2 * 100vw);
	}
	33% {
		width: 40vw;
		height: 40vw;
		left: 30vw;
		top: calc((var(--heightRatio) - 0.4) / 2 * 100vw);
	}
	66% {
		width: 80vw;
		height: 80vw;
		left: 10vw;
		top: calc((var(--heightRatio) - 0.8) / 2 * 100vw);
	}
	100% {
		width: 60vw;
		height: 60vw;
		left: 20vw;
		top: calc((var(--heightRatio) - 0.6) / 2 * 100vw);
	}
}

@media only screen and (max-width: 450px) {
	.ZH-HANT {
		.Paragraph {
			h1 {
				font-size: 7vw !important;
			}
			p {
				font-size: 3.75vw !important;
				line-height: 5vw !important;
			}
		}

		.Section.One {
			.ChapterName {
				background-image: initial !important;
				left: 10% !important;
				width: 80vw !important;

				p {
					font-size: 14vw !important;
					line-height: 14vw !important;
					text-align: center;
				}
			}

			.ChapterYear {
				top: 85vh !important;

				p {
					font-size: 6vw !important;
				}
			}
		}

		.Slider {
			.Slide {
				h1 {
					font-size: 3vw;
					margin: 2vw;
				}

				p {
					width: 80vw !important;
				}

				.FooterSwitch p {
					width: auto !important;
				}
			}
		}

		.VideoMask p {
			line-height: 5vw !important;
			width: 85vw !important;
		}
	}

	.Paragraph {
		h1 {
			font-size: 7vw !important;
		}
		p {
			font-size: 3.75vw !important;
			line-height: 5vw !important;
		}
	}

	.ChapterCaption p {
		// width: 80vw;
		font-size: 2.8125vw !important;
		line-height: 3.5vw !important;
	}

	.VideoContainer {
		height: 100vh !important;
	}

	.Video {
		top: 38vh !important;
	}

	.DotsRect {
		background-size: 7.5% !important;
		height: 95% !important;
	}

	.Caption p {
		line-height: 4.5vw !important;
		font-size: 3vw !important;
	}

	.MusicNotes {
		width: 12vw !important;
		height: 6vw !important;
	}

	.VideoMask {
		height: 100vh !important;

		p {
			font-size: 3.5vw !important;
			line-height: 5vw !important;
			width: 68vw;
		}
	}

	.VideoMaskBtn {
		width: 15vw !important;
		height: 15vw !important;
	}

	.Section {
		height: 100vh !important;
	}

	.Section.One {
		.ChapterName {
			top: 60% !important;
			left: 20% !important;
			width: 60vw !important;
			height: 40vw !important;

			p {
				font-size: 5.7vw;
			}
		}

		.ChapterName.ZH {
			right: 22.5%;

			p {
				font-size: 4vw;
			}
		}

		.ChapterYear {
			position: absolute;
			top: 82.5% !important;
			left: 33% !important;

			p {
				font-size: 10vw !important;
			}
		}

		h1,
		p {
			margin: 0;
		}

		.ImgContainer.Portrait {
			width: 60vw !important;
			height: 72vw !important;
			left: 20vw !important;
			top: 20vh !important;
		}

		.Ellipse.Green {
			width: 160vw !important;
			height: 160vw !important;
			top: -10% !important;
			left: -45% !important;
		}

		.Ellipse.Blue {
			background-image: initial !important;
		}
	}

	.clipContent {
		height: 100vh !important;
	}

	.Section.Two {
		.Ellipse.Blue {
			width: 140vw !important;
			height: 140vw !important;
			left: -50vw !important;
			bottom: -70% !important;
		}

		.Ellipse.Green {
			width: 120vw !important;
			height: 120vw !important;
			right: -20vw !important;
			top: 44.5%;
		}
	}

	.Slide {
		height: 100vh !important;

		h1 {
			font-size: 10vw !important;
		}

		P {
			font-size: 4vw !important;
			line-height: 6vw !important;
			width: 80vw;
			text-align: justify !important;
		}

		.MusicNote {
			width: 7.5vw !important;
			height: 7.5vw !important;
		}
	}

	.ProgressBarBG,
	.ProgressBar {
		height: 2vw !important;
		bottom: 6vh !important;
	}

	.SliderBtn {
		width: 7.5vw !important;
		height: 7.5vw !important;
		background-image: url("../Assets/Images/Chapter/Slider/Arrow.png");
	}

	.PrevSlide {
		bottom: 4.5vh !important;
		left: 10vw !important;
	}

	.NextSlide {
		bottom: 4.5vh !important;
		right: 10vw !important;
	}

	.Slide.Zero {
		height: 100vh !important;

		.YellowEllipse {
			width: 225vw !important;
			height: 225vw !important;
			left: -62.5vw !important;
		}
	}

	.Slide.One {
		.ScrollGIF {
			width: 20vw !important;
			height: 32vw !important;
			right: 20vw !important;
			bottom: 10vh !important;
		}

		.FlippedClick {
			width: 16vw !important;
			height: 16vw !important;
			right: 5vw !important;
			bottom: 10vh !important;

			background-image: url("../Assets/Images/Chapter/Slider/Click_m.png") !important;
		}
	}

	.Legacy {
		height: 100vh !important;

		h1 {
			font-size: 7vw !important;
		}

		p {
			width: 80vw !important;
			font-size: 3.75vw !important;
			line-height: 5vw !important;
		}

		.Ellipse.Left {
			width: 120vw !important;
			height: 120vw !important;
			left: -35vw !important;
		}

		.Ellipse.Right {
			width: 135vw !important;
			height: 135vw !important;
			right: -50vw !important;
		}

		.FooterSwitch {
			right: 5vw !important;
			bottom: 5vh !important;

			p {
				width: initial;
				white-space: nowrap;
				position: static !important;
			}
		}
	}

	.FooterBtn {
		.FooterArrow {
			width: 7vw !important;
			height: 4vw !important;
		}

		p {
			font-size: 5.25vw !important;
			line-height: 5.25vw !important;
			width: initial !important;
			padding-right: 3vw;
		}
	}
}
