// @import "./Chapter.scss";

@media only screen and (max-width: 450px) {
	.Menu {
		width: 200vw !important;

		.Ellipse.Green {
			width: 100vw !important;
			height: 100vw !important;
			left: -20vw !important;
			bottom: -20vh !important;
		}

		.Ellipse.Blue {
			width: 15vw !important;
			height: 15vw !important;
		}

		.LeftHalf {
			width: 100vw !important;
			height: 100vh;
			flex: initial !important;
			width: 100vw !important;
			transition: all 1s;
			position: absolute;

			.BtnContainer {
				top: 10vh !important;
				left: 10vw !important;
				width: 85vw !important;
				height: 90vh;
				transition: all 1s;

				.MenuBtn {
					height: 6vh !important;
					margin-top: 3.5vh;
					margin-bottom: 3.5vh;

					p {
						font-size: 10.5vw !important;
						margin-top: 0.8vw;
					}
				}

				.MenuBtn.Home {
					width: 6.5vw;
				}

				.MenuBtn.Smetana {
					width: 55vw !important;
					top: 8.5vh !important;
				}

				.MenuBtn.Dvorak {
					width: 60vw !important;
					top: 17vh !important;
				}

				.MenuBtn.Janacek {
					width: 45vw !important;
					top: 25.5vh !important;
				}

				.MenuBtn.Martinu {
					width: 65vw !important;
					top: 34vh !important;
				}

				.MenuBtn.NationalTheatreBrno {
					width: 85vw !important;
					top: 44vh !important;
				}

				.MenuBtn.Share {
					width: 85vw !important;
					top: 53vh !important;
				}
			}

			.HKAFLogo {
				width: 35vw !important;
				height: 10vw !important;
				left: 10vw !important;
			}

			.PlusLogo {
				width: 35vw !important;
				height: 10vw !important;
				left: 55vw !important;
			}

			.CreditSwitch {
				position: absolute;
				left: 60vw !important;
				top: 72vh !important;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				transition: all 1s;

				p {
					font-family: adorn-coronet, sans-serif;
					font-size: 10.5vw !important;
					color: #807d7a;
				}

				.SwitchArrow {
					background-image: url("../Assets/Images/Menu/SwitchArrow.png");
					background-size: contain;
					background-repeat: no-repeat;
					width: 5vw;
					height: 5vw;
					padding-left: 3vw;
					transform: rotateY(180deg);
				}
			}
		}

		.RightHalf {
			position: absolute;
			left: 100vw;
			flex: initial !important;
			width: 100vw !important;
			height: 100vh;
			transition: all 1s;

			.Contributors {
				left: 15vw !important;
				top: 10vh !important;

				p {
					font-size: 4.5vw !important;
					line-height: 7vw !important;
					margin-bottom: 4vh !important;
				}

				.Logo {
					margin-top: 2vh !important;
					margin-bottom: 2vh !important;
				}

				.Logo.NDB {
					width: 30vw !important;
					height: 17vw !important;
					background-position: initial !important;
				}

				.Logo.MartinuFoundation {
					width: 21vw !important;
					height: 18vw !important;
				}

				.Logo.TIC {
					width: 40vw !important;
					height: 8vw !important;
				}

				.LangSwitch {
					position: absolute;
					left: -110vw;
					top: 60vh;

					.LangBtn {
						margin: 6vw 5vw !important;

						p {
							font-size: 5vw !important;
						}
					}
				}

				.MenuSwitch {
					position: absolute;
					left: 0vw !important;
					top: 75vh !important;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					cursor: pointer;

					p {
						font-family: adorn-coronet, sans-serif;
						font-size: 10.5vw !important;
						color: #807d7a;
						margin: initial !important;
					}

					.SwitchArrow {
						background-image: url("../Assets/Images/Menu/SwitchArrow.png");
						background-size: contain;
						background-repeat: no-repeat;
						width: 5vw;
						height: 5vw;
						padding-right: 3vw;
					}
				}
			}
		}
	}

	.Menu.ZH-HANT {
		.LeftHalf {
			.BtnContainer {
				.MenuBtn {
					width: initial !important;

					p {
						font-size: 7vw !important;
					}
				}

				.MenuBtn.Smetana,
				.MenuBtn.Dvorak,
				.MenuBtn.Janacek,
				.MenuBtn.Martinu {
					width: initial !important;
				}
			}

			.CreditSwitch {
				left: 55vw !important;

				p {
					font-family: yu-mincho-pr6, sans-serif !important;
					font-size: 7vw !important;
				}
			}
		}

		.RightHalf {
			.MenuSwitch {
				p {
					font-family: yu-mincho-pr6, sans-serif !important;
					font-size: 7vw !important;
				}
			}
		}
	}

	.Menu.RightHalf {
		.LeftHalf {
			left: -100vw !important;

			.BtnContainer {
				left: -90vw !important;
			}

			.CreditSwitch {
				left: -40vw !important;
			}
		}

		.RightHalf {
			left: 0vw !important;
		}
	}
}

.Menu {
	position: fixed;
	top: -100vh;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #f5f5f5;
	transition: all 1s;
	z-index: 1000;
	white-space: pre-line;
	display: flex;
	flex-direction: row;
	overflow: hidden;

	.Ellipse.Green {
		background-image: url("../Assets/Images/Chapter/Section2/GreenEllipse.png");
		width: 30vw;
		height: calc(var(--heightRatio) * 50vw);
		left: -5%;
		bottom: calc(var(--heightRatio) * -20vw);
	}

	.Ellipse.Blue {
		background-image: url("../Assets/Images/Menu/ButtonEllipse.png");
		width: 7.5vw;
		height: 7.5vw;
		position: absolute;
		transition: all 0s;
	}

	.RightHalf {
		flex: 2;
		background-color: #ffffff;

		.Contributors {
			position: absolute;
			left: 72.5vw;
			top: 7.5vh;

			p {
				font-family: acumin-pro, sans-serif;
				font-size: 1vw;
				line-height: 1.75vw;
				margin-bottom: 2vw;
				text-align: left;
			}

			.Logo {
				background-size: contain;
				background-repeat: no-repeat;
				margin-top: 1vw;
				margin-bottom: 1vw;
				cursor: pointer;
			}

			.Logo.NDB {
				background-image: url("../Assets/Images/Menu/NdBLogo.png");
				background-attachment: initial;
				width: 10vw;
				height: 5.8vw;
			}

			.Logo.MartinuFoundation {
				background-image: url("../Assets/Images/Menu/MartinuFoundationLogo.png");
				width: 7vw;
				height: 6vw;
			}

			.Logo.TIC {
				background-image: url("../Assets/Images/Menu/TICLogo.png");
				width: 10vw;
				height: 2vw;
			}

			.LangSwitch {
				display: flex;
				flex-direction: row;

				.LangBtn {
					margin: 1vw;
					cursor: pointer;

					p {
						font-size: 1.5vw;
					}
				}
			}

			.LangSwitch.ZH {
				.LangBtn.ZH {
					p {
						color: orange;
					}
				}
			}

			.LangSwitch.EN {
				.LangBtn.EN {
					p {
						color: orange;
					}
				}
			}
		}
	}

	.LeftHalf {
		flex: 4;

		.BtnContainer {
			position: absolute;
			top: 5vh;
			left: 7.5vw;
			width: 50vw;
			height: 90vh;

			.MenuBtn {
				background-repeat: no-repeat;
				background-size: contain;
				height: 3.5vw;
				margin-top: 3.5vh;
				margin-bottom: 3.5vh;
				cursor: pointer;
				position: absolute;
				align-items: center;
				justify-content: center;

				p {
					font-family: adorn-coronet, sans-serif;
					font-size: 3.3vw;
					margin-top: 0.8vw;
					color: #807d7a;
				}
			}

			.MenuBtn:hover {
				p {
					color: #192d42;
				}
			}

			.MenuBtn.Home {
				width: 6.5vw;
			}

			.MenuBtn.Smetana {
				background-image: url("../Assets/Images/Menu/Button/Smetana.png");
				width: 15.5vw;
				top: 9.7vh;
			}

			.MenuBtn.Dvorak {
				background-image: url("../Assets/Images/Menu/Button/Dvorak.png");
				width: 17vw;
				top: 19.4vh;
			}

			.MenuBtn.Janacek {
				background-image: url("../Assets/Images/Menu/Button/Janacek.png");
				width: 12.5vw;
				top: 29.1vh;
			}

			.MenuBtn.Martinu {
				background-image: url("../Assets/Images/Menu/Button/Martinu.png");
				width: 20vw;
				top: 38.8vh;
			}

			.MenuBtn.NationalTheatreBrno {
				width: 30vw;
				top: 48.5vh;
			}

			.MenuBtn.Share {
				width: 30vw;
				top: 58.2vh;
			}
		}

		.BtnContainer.Home {
			.MenuBtn.Home {
				p {
					color: #192d42;
				}
			}
		}

		.BtnContainer.Smetana {
			.MenuBtn.Smetana {
				background-image: url("../Assets/Images/Menu/Button/Smetana_Active.png");
			}
		}

		.BtnContainer.Dvorak {
			.MenuBtn.Dvorak {
				background-image: url("../Assets/Images/Menu/Button/Dvorak_Active.png");
			}
		}

		.BtnContainer.Janacek {
			.MenuBtn.Janacek {
				background-image: url("../Assets/Images/Menu/Button/Janacek_Active.png");
			}
		}

		.BtnContainer.Martinu {
			.MenuBtn.Martinu {
				background-image: url("../Assets/Images/Menu/Button/Martinu_Active.png");
			}
		}

		.BtnContainer.Ndb {
			.MenuBtn.NationalTheatreBrno {
				p {
					color: #192d42;
				}
			}
		}

		.HKAFLogo {
			background-image: url("../Assets/Images/Map/MaskLogo.png");
			background-repeat: no-repeat;
			background-size: contain;
			width: 15vw;
			height: calc(var(--heightRatio) * 7.5vw);
			position: absolute;
			left: 7.5%;
			bottom: 6.1%;
			z-index: 10;
			cursor: pointer;
		}

		.PlusLogo {
			background-image: url("../Assets/Images/Menu/PlusLogo.png");
			background-repeat: no-repeat;
			background-size: contain;
			width: 10vw;
			height: calc(var(--heightRatio) * 7.5vw);
			position: absolute;
			left: 25%;
			bottom: 6%;
			z-index: 10;
			cursor: pointer;
		}
	}
}

.Menu.Opened {
	top: 0;
}

.Menu.ZH-HANT {
	.MenuBtn {
		p {
			font-size: 2vw !important;
			font-family: yu-mincho-pr6, sans-serif !important;
		}
	}

	.MenuBtn.Home {
		width: 5vw !important;
	}

	.MenuBtn.Smetana {
		background-image: initial !important;
		width: 22.5vw !important;
	}

	.MenuBtn.Dvorak {
		background-image: initial !important;
		width: 20vw !important;
	}

	.MenuBtn.Janacek {
		background-image: initial !important;
		width: 20vw !important;
	}

	.MenuBtn.Martinu {
		background-image: initial !important;
		width: 22.5vw !important;
	}
}
